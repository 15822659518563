$pattern-prefix : 'pattern' !default;

$pattern-sizes : (sm:10px,
    md:25px,
    lg:50px,
    xl:100px,
) !default;

$pattern-list: ('checks',
    'grid',
    'grid',
    'dots',
    'cross-dots',
    'vertical-lines',
    'horizontal-lines',
    'diagonal-lines',
    'vertical-stripes',
    'horizontal-stripes',
    'diagonal-stripes',
    'triangles',
    'zigzag'
) !default;

$dots : (sm:.5px,
    md:1px,
    lg:1.5px,
    xl:2px) !default;

// .bg-checks-{sm, md, lg, xl}
@if index($pattern-list, 'checks') {

    @each $name,
    $size in $pattern-sizes {
        .#{$pattern-prefix}-checks-#{$name} {
            background-image:
                repeating-linear-gradient(45deg, currentColor 25%, transparent 25%, transparent 75%, currentColor 75%, currentColor),
                repeating-linear-gradient(45deg, currentColor 25%, transparent 25%, transparent 75%, currentColor 75%, currentColor);
            background-position: 0 0,
            #{$size} #{$size};
            background-size: calc(2 * #{$size}) calc(2 * #{$size});
        }
    }
}

// .bg-grid-{sm, md, lg, xl}
@if index($pattern-list, 'grid') {

    @each $name,
    $size in $pattern-sizes {
        .#{$pattern-prefix}-grid-#{$name} {
            background-image:
                linear-gradient(currentColor 1px, transparent 1px),
                linear-gradient(to right, currentColor 1px, transparent 1px);
            background-size: #{$size} #{$size};
            // background-position: calc(-0.5 * #{$size}) calc(-0.5 * #{$size});
        }
    }
}

// .bg-dots-{sm, md, lg, xl}
@if index($pattern-list, 'dots') {

    @each $name,
    $size in $dots {
        .#{$pattern-prefix}-dots-#{$name} {
            background-image: radial-gradient(currentColor #{$size}, transparent #{$size});
            background-size: calc(10 * #{$size}) calc(10 * #{$size});
        }
    }
}

// .bg-cross-dots-{sm, md, lg, xl}
@if index($pattern-list, 'cross-dots') {

    @each $name,
    $size in $dots {
        .#{$pattern-prefix}-cross-dots-#{$name} {
            background-image: radial-gradient(currentColor #{$size}, transparent #{$size}),
            radial-gradient(currentColor #{$size}, transparent #{$size});
            background-size: calc(20 * #{$size}) calc(20 * #{$size});
            background-position: 0 0,
            calc(10 * #{$size}) calc(10 * #{$size});
        }
    }
}

@each $name,
$size in $pattern-sizes {

    // .bg-vertical-lines-{sm, md, lg, xl}
    @if index($pattern-list, 'vertical-lines') {
        .#{$pattern-prefix}-vertical-lines-#{$name} {
            background-image: repeating-linear-gradient(to right,
                    currentColor,
                    currentColor 1px,
                    transparent 1px,
                    transparent);
            background-size: #{$size} #{$size};
        }
    }

    // .bg-horizontal-lines-{sm, md, lg, xl}
    @if index($pattern-list, 'horizontal-lines') {
        .#{$pattern-prefix}-horizontal-lines-#{$name} {
            background-image: repeating-linear-gradient(0deg,
                    currentColor,
                    currentColor 1px,
                    transparent 1px,
                    transparent);
            background-size: #{$size} #{$size};
        }
    }

    // .bg-diagonal-lines-{sm, md, lg, xl}
    @if index($pattern-list, 'diagonal-lines') {
        .#{$pattern-prefix}-diagonal-lines-#{$name} {
            background-image: repeating-linear-gradient(45deg,
                    currentColor 0,
                    currentColor 1px,
                    transparent 0,
                    transparent 50%);
            background-size: #{$size} #{$size};
        }
    }
}


@each $name,
$size in $pattern-sizes {

    // .bg-vertical-stripes-{sm, md, lg, xl}
    @if index($pattern-list, 'vertical-stripes') {
        .#{$pattern-prefix}-vertical-stripes-#{$name} {
            background-image: linear-gradient(90deg, transparent 50%, currentColor 50%);
            background-size: #{$size} #{$size};
        }
    }

    // .bg-horizontal-stripes-{sm, md, lg, xl}
    @if index($pattern-list, 'horizontal-stripes') {
        .#{$pattern-prefix}-horizontal-stripes-#{$name} {
            background-image: linear-gradient(0deg, transparent 50%, currentColor 50%);
            background-size: #{$size} #{$size};
        }
    }

    // .bg-diagonal-stripes-{sm, md, lg, xl}
    @if index($pattern-list, 'diagonal-stripes') {
        .#{$pattern-prefix}-diagonal-stripes-#{$name} {
            background: repeating-linear-gradient(45deg,
                transparent,
                transparent #{$size},
                currentColor #{$size},
                currentColor calc(2 * #{$size}));
        }
    }
}

// .bg-zigzag-{sm, md, lg, xl}
@if index($pattern-list, 'zigzag') {

    @each $name,
    $size in $pattern-sizes {
        .#{$pattern-prefix}-zigzag-#{$name} {
            background: linear-gradient(135deg, currentColor 25%, transparent 25%) -#{$size} 0,
            linear-gradient(225deg, currentColor 25%, transparent 25%) -#{$size} 0,
            linear-gradient(315deg, currentColor 25%, transparent 25%),
            linear-gradient(45deg, currentColor 25%, transparent 25%);
            background-size: calc(2 * #{$size}) calc(2 * #{$size});
        }
    }
}

// .bg-triangles-{sm, md, lg, xl}
@if index($pattern-list, 'triangles') {

    @each $name,
    $size in $pattern-sizes {
        .#{$pattern-prefix}-triangles-#{$name} {
            background-image: linear-gradient(45deg,
                    currentColor 50%,
                    transparent 50%);
            background-size: #{$size} #{$size};
        }
    }
}

// .text-pattern
.text-pattern {
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Width and Height
@each $name,
$size in $pattern-sizes {
    .#{$pattern-prefix}-w-#{$name} {
        width: $size;
    }

    .#{$pattern-prefix}-h-#{$name} {
        height: $size;
    }
}