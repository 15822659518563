.infinite {
  animation-iteration-count: infinite !important;
}
.ld { transform-origin: 50% 50%; transform-box: fill-box; }
@keyframes ld-blur-in {
  100% { filter: blur(0); opacity: 1 }
  0% { filter: blur(10%); opacity: 0 }
}
.ld.ld-blur-in {
  animation: ld-blur-in 1s 1 linear; animation-fill-mode: forwards;filter: blur(10%);opacity: 0; 
}
@keyframes ld-blur-out {
  0% { filter: blur(0); opacity: 1 }
  100% { filter: blur(10%); opacity: 0 }
}
.ld.ld-blur-out {
  animation: ld-blur-out 1s 1 linear; animation-fill-mode: forwards;filter: blur(0%);opacity: 1; 
}
@keyframes ld-bounce-alt-out
{
  0% {
    animation-timing-function: cubic-bezier(0.094,0.3361,-1.0597,0.6688);
    transform: matrix(1,0,0,1,0,0);
    opacity: 1;
  }
  3.4% {
    animation-timing-function: cubic-bezier(0.0867,0.1564,0.2047,0.9488);
    transform: matrix(0.52,0,0,0.52,0,0);
    opacity: 1;
  }
  16.8% {
    animation-timing-function: cubic-bezier(0.5435,0.0974,0.4027,0.9465);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  32% {
    animation-timing-function: cubic-bezier(0.5116,0.0971,0.4412,0.947);
    transform: matrix(0.212,0,0,0.212,0,0);
    opacity: 1;
  }
  50.1% {
    animation-timing-function: cubic-bezier(0.3959,0.1021,0.5847,1);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  64.9% {
    animation-timing-function: cubic-bezier(0.3789,0.0955,0.5974,1);
    transform: matrix(0.082,0,0,0.082,0,0);
    opacity: 1;
  }
  83.5% {
    animation-timing-function: cubic-bezier(0.3358,0,0.6626,1);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  93.1% {
    animation-timing-function: cubic-bezier(0.3201,0,0.6971,0.226);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
}
.ld.ld-bounce-alt-out {
  animation: ld-bounce-alt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0); 
}
@keyframes ld-bounce-alt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0955,0.3456,-1.5085,0.6684);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  3.3% {
    animation-timing-function: cubic-bezier(0.0913,0.1631,0.2418,0.9474);
    transform: matrix(0.607,0,0,0.607,0,0);
    opacity: 1;
  }
  16.4% {
    animation-timing-function: cubic-bezier(0.5391,0.1345,0.3944,0.9496);
    transform: matrix(1.079,0,0,1.079,0,0);
    opacity: 1;
  }
  32.3% {
    animation-timing-function: cubic-bezier(0.5053,0.1012,0.4542,0.9453);
    transform: matrix(0.84,0,0,0.84,0,0);
    opacity: 1;
  }
  49.7% {
    animation-timing-function: cubic-bezier(0.4074,0.0845,0.5696,1);
    transform: matrix(1.033,0,0,1.033,0,0);
    opacity: 1;
  }
  65.3% {
    animation-timing-function: cubic-bezier(0.3701,0,0.6111,1);
    transform: matrix(0.939,0,0,0.939,0,0);
    opacity: 1;
  }
  82.6% {
    animation-timing-function: cubic-bezier(0.336,0,0.6629,1);
    transform: matrix(1.009,0,0,1.009,0,0);
    opacity: 1;
  }
  94.3% {
    animation-timing-function: cubic-bezier(0.3368,0,0.6709,1);
    transform: matrix(0.994,0,0,0.994,0,0);
    opacity: 1;
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
    opacity: 1;
  }
}
.ld.ld-bounce-alt-in {
  animation: ld-bounce-alt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.01,0,0,0.01,0,0); 
}
@keyframes ld-bounce-out
{
  0% {
    animation-timing-function: cubic-bezier(0.0725,0.3371,0.3061,0.6653);
    transform: matrix(1,0,0,1,0,0);
    opacity: 1;
  }
  5.7% {
    animation-timing-function: cubic-bezier(0.0823,0.1441,-0.1523,0.9205);
    transform: matrix(0.5,0,0,0.5,0,0);
    opacity: 1;
  }
  12.6% {
    animation-timing-function: cubic-bezier(0.7924,0.158,0.0272,0.9224);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  24.3% {
    animation-timing-function: cubic-bezier(0.8378,0.1302,0.0581,0.9268);
    transform: matrix(0.489,0,0,0.489,0,0);
    opacity: 1;
  }
  37.6% {
    animation-timing-function: cubic-bezier(0.662,0.0985,0.2767,0.9462);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  49.3% {
    animation-timing-function: cubic-bezier(0.6012,0.1308,0.3182,0.9506);
    transform: matrix(0.262,0,0,0.262,0,0);
    opacity: 1;
  }
  62.6% {
    animation-timing-function: cubic-bezier(0.4763,0.0773,0.4825,1);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  73.8% {
    animation-timing-function: cubic-bezier(0.4466,0.0766,0.4988,1);
    transform: matrix(0.115,0,0,0.115,0,0);
    opacity: 1;
  }
  87.6% {
    animation-timing-function: cubic-bezier(0.3423,0,0.6554,1);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  94.9% {
    animation-timing-function: cubic-bezier(0.3661,0,0.6589,0.554);
    transform: matrix(0.013,0,0,0.013,0,0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
}
.ld.ld-bounce-out {
  animation: ld-bounce-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0); 
}
@keyframes ld-bounce-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0383,0.3388,0.0421,0.6652);
    transform: matrix(0.01,0,0,0.01,0,0);
    opacity: 1;
  }
  4.7% {
    animation-timing-function: cubic-bezier(0.3296,0.3336,0.5772,0.6672);
    transform: matrix(0.556,0,0,0.556,0,0);
    opacity: 1;
  }
  8.4% {
    animation-timing-function: cubic-bezier(0.0781,0.1617,0.0874,0.9301);
    transform: matrix(0.979,0,0,0.979,0,0);
    opacity: 1;
  }
  12.4% {
    animation-timing-function: cubic-bezier(0.8341,0.1496,0.8634,0.7673);
    transform: matrix(1.153,0,0,1.153,0,0);
    opacity: 1;
  }
  16.3% {
    animation-timing-function: cubic-bezier(0.3264,0.3332,0.5758,0.6695);
    transform: matrix(1.008,0,0,1.008,0,0);
    opacity: 1;
  }
  21.2% {
    animation-timing-function: cubic-bezier(0.0921,0.1883,0.3277,1);
    transform: matrix(0.704,0,0,0.704,0,0);
    opacity: 1;
  }
  24.5% {
    animation-timing-function: cubic-bezier(0.6905,0.0944,0.8759,0.7624);
    transform: matrix(0.626,0,0,0.626,0,0);
    opacity: 1;
  }
  27.7% {
    animation-timing-function: cubic-bezier(0.3688,0.332,0.624,0.6684);
    transform: matrix(0.704,0,0,0.704,0,0);
    opacity: 1;
  }
  32.6% {
    animation-timing-function: cubic-bezier(0.1368,0.2364,0.2451,0.9049);
    transform: matrix(0.958,0,0,0.958,0,0);
    opacity: 1;
  }
  37.4% {
    animation-timing-function: cubic-bezier(0.5936,0.0846,0.3495,1);
    transform: matrix(1.085,0,0,1.085,0,0);
    opacity: 1;
  }
  49.5% {
    animation-timing-function: cubic-bezier(0.5522,0.0981,0.3807,1);
    transform: matrix(0.802,0,0,0.802,0,0);
    opacity: 1;
  }
  62.4% {
    animation-timing-function: cubic-bezier(0.4497,0.1349,0.4911,1);
    transform: matrix(1.044,0,0,1.044,0,0);
    opacity: 1;
  }
  74.1% {
    animation-timing-function: cubic-bezier(0.429,0,0.5239,1);
    transform: matrix(0.914,0,0,0.914,0,0);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.3501,0,0.6422,1);
    transform: matrix(1.013,0,0,1.013,0,0);
    opacity: 1;
  }
  95.8% {
    animation-timing-function: cubic-bezier(0.3653,0,0.6776,1);
    transform: matrix(0.992,0,0,0.992,0,0);
    opacity: 1;
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
    opacity: 1;
  }
}
.ld.ld-bounce-in {
  animation: ld-bounce-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.01,0,0,0.01,0,0); 
}
@keyframes ld-spring-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0741,0.3499,0.0735,0.662);
    transform: translate(-49.5%,0%);
    opacity: 0;
  }
  5.8% {
    animation-timing-function: cubic-bezier(0.0811,0.1439,0.0534,0.9354);
    transform: translate(-22.5%,0%);
    opacity: 1;
  }
  16.5% {
    animation-timing-function: cubic-bezier(0.6042,0.1355,0.2754,0.9675);
    transform: translate(6.9%,0%);
    opacity: 1;
  }
  32.4% {
    animation-timing-function: cubic-bezier(0.6652,0.1048,0.269,0.9232);
    transform: translate(-15.25%,0%);
    opacity: 1;
  }
  49.8% {
    animation-timing-function: cubic-bezier(0.5122,0.1079,0.4428,0.9413);
    transform: translate(3.15%,0%);
    opacity: 1;
  }
  65.3% {
    animation-timing-function: cubic-bezier(0.4346,0.1202,0.5186,1);
    transform: translate(-6.05%,0%);
    opacity: 1;
  }
  82.6% {
    animation-timing-function: cubic-bezier(0.3509,0,0.6434,1);
    transform: translate(0.9%,0%);
    opacity: 1;
  }
  94.3% {
    animation-timing-function: cubic-bezier(0.3419,0,0.6762,0.4407);
    transform: translate(-0.55%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-spring-ltr-in {
  animation: ld-spring-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,-49.5,0); 
}
@keyframes ld-spring-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0741,0.3499,0.0735,0.662);
    transform: translate(49.5%,0%);
    opacity: 0;
  }
  5.8% {
    animation-timing-function: cubic-bezier(0.0811,0.1439,0.0534,0.9354);
    transform: translate(22.5%,0%);
    opacity: 1;
  }
  16.5% {
    animation-timing-function: cubic-bezier(0.6042,0.1355,0.2754,0.9675);
    transform: translate(-6.9%,0%);
    opacity: 1;
  }
  32.4% {
    animation-timing-function: cubic-bezier(0.6652,0.1048,0.269,0.9232);
    transform: translate(15.25%,0%);
    opacity: 1;
  }
  49.8% {
    animation-timing-function: cubic-bezier(0.5122,0.1079,0.4428,0.9413);
    transform: translate(-3.15%,0%);
    opacity: 1;
  }
  65.3% {
    animation-timing-function: cubic-bezier(0.4346,0.1202,0.5186,1);
    transform: translate(6.05%,0%);
    opacity: 1;
  }
  82.6% {
    animation-timing-function: cubic-bezier(0.3509,0,0.6434,1);
    transform: translate(-0.9%,0%);
    opacity: 1;
  }
  94.3% {
    animation-timing-function: cubic-bezier(0.3419,0,0.6762,0.4407);
    transform: translate(0.55%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-spring-rtl-in {
  animation: ld-spring-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,49.5,0); 
}
@keyframes ld-spring-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0741,0.3499,0.0735,0.662);
    transform: translate(0%,-49.5%);
    opacity: 0;
  }
  5.8% {
    animation-timing-function: cubic-bezier(0.0811,0.1439,0.0534,0.9354);
    transform: translate(0%,-22.5%);
    opacity: 1;
  }
  16.5% {
    animation-timing-function: cubic-bezier(0.6042,0.1355,0.2754,0.9675);
    transform: translate(0%,6.9%);
    opacity: 1;
  }
  32.4% {
    animation-timing-function: cubic-bezier(0.6652,0.1048,0.269,0.9232);
    transform: translate(0%,-15.25%);
    opacity: 1;
  }
  49.8% {
    animation-timing-function: cubic-bezier(0.5122,0.1079,0.4428,0.9413);
    transform: translate(0%,3.15%);
    opacity: 1;
  }
  65.3% {
    animation-timing-function: cubic-bezier(0.4346,0.1202,0.5186,1);
    transform: translate(0%,-6.05%);
    opacity: 1;
  }
  82.6% {
    animation-timing-function: cubic-bezier(0.3509,0,0.6434,1);
    transform: translate(0%,0.9%);
    opacity: 1;
  }
  94.3% {
    animation-timing-function: cubic-bezier(0.3419,0,0.6762,0.4407);
    transform: translate(0%,-0.55%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-spring-ttb-in {
  animation: ld-spring-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,-49.5); 
}
@keyframes ld-spring-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0741,0.3499,0.0735,0.662);
    transform: translate(0%,49.5%);
    opacity: 0;
  }
  5.8% {
    animation-timing-function: cubic-bezier(0.0811,0.1439,0.0534,0.9354);
    transform: translate(0%,22.5%);
    opacity: 1;
  }
  16.5% {
    animation-timing-function: cubic-bezier(0.6042,0.1355,0.2754,0.9675);
    transform: translate(0%,-6.9%);
    opacity: 1;
  }
  32.4% {
    animation-timing-function: cubic-bezier(0.6652,0.1048,0.269,0.9232);
    transform: translate(0%,15.25%);
    opacity: 1;
  }
  49.8% {
    animation-timing-function: cubic-bezier(0.5122,0.1079,0.4428,0.9413);
    transform: translate(0%,-3.15%);
    opacity: 1;
  }
  65.3% {
    animation-timing-function: cubic-bezier(0.4346,0.1202,0.5186,1);
    transform: translate(0%,6.05%);
    opacity: 1;
  }
  82.6% {
    animation-timing-function: cubic-bezier(0.3509,0,0.6434,1);
    transform: translate(0%,-0.9%);
    opacity: 1;
  }
  94.3% {
    animation-timing-function: cubic-bezier(0.3419,0,0.6762,0.4407);
    transform: translate(0%,0.55%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-spring-btt-in {
  animation: ld-spring-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,49.5); 
}
@keyframes ld-jump-alt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.9281,0.063,0.9339,0.8573);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  5.7% {
    animation-timing-function: cubic-bezier(0.4285,0.3322,0.4777,0.669);
    transform: matrix(0.28,0,0,0.28,0,0);
  }
  10.9% {
    animation-timing-function: cubic-bezier(0.0358,0.084,0.2547,1);
    transform: matrix(0.991,0,0,0.991,0,0);
  }
  20.1% {
    animation-timing-function: cubic-bezier(0.7964,0.1337,0.8635,0.7525);
    transform: matrix(0.5,0,0,0.5,0,0);
  }
  24.9% {
    animation-timing-function: cubic-bezier(0.4857,0.3288,0.4241,0.6751);
    transform: matrix(0.643,0,0,0.643,0,0);
  }
  29.3% {
    animation-timing-function: cubic-bezier(0.069,0.1323,0.299,1);
    transform: matrix(0.994,0,0,0.994,0,0);
  }
  37.1% {
    animation-timing-function: cubic-bezier(0.71,0,0.8669,0.8745);
    transform: matrix(0.75,0,0,0.75,0,0);
  }
  44.9% {
    animation-timing-function: cubic-bezier(0.1195,0.2278,0.3743,1);
    transform: matrix(0.998,0,0,0.998,0,0);
  }
  51.6% {
    animation-timing-function: cubic-bezier(0.7743,0,0.5778,0.8867);
    transform: matrix(0.875,0,0,0.875,0,0);
  }
  58.2% {
    animation-timing-function: cubic-bezier(0.1362,0.3881,0.5196,1);
    transform: matrix(0.998,0,0,0.998,0,0);
  }
  63.9% {
    animation-timing-function: cubic-bezier(0.5363,0,0.7437,1);
    transform: matrix(0.938,0,0,0.938,0,0);
  }
  69.5% {
    animation-timing-function: cubic-bezier(0.1755,0.5617,0.6288,1);
    transform: matrix(0.999,0,0,0.999,0,0);
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.3931,0,0.722,1.4447);
    transform: matrix(0.969,0,0,0.969,0,0);
  }
  79.1% {
    animation-timing-function: cubic-bezier(0.2945,0,0.6251,1);
    transform: matrix(0.999,0,0,0.999,0,0);
  }
  83.2% {
    animation-timing-function: cubic-bezier(0.1679,0,0.9959,1.0111);
    transform: matrix(0.984,0,0,0.984,0,0);
  }
  87.3% {
    animation-timing-function: cubic-bezier(0.3158,0,0.639,1);
    transform: matrix(0.999,0,0,0.999,0,0);
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3636,0,0.6809,1);
    transform: matrix(0.992,0,0,0.992,0,0);
  }
  94.2% {
    animation-timing-function: cubic-bezier(0.323,0,0.6275,1);
    transform: matrix(1,0,0,1,0,0);
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
  }
}
.ld.ld-jump-alt-in {
  animation: ld-jump-alt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.01,0,0,0.01,0,0); 
}
@keyframes ld-jump-alt-out
{
  0% {
    animation-timing-function: cubic-bezier(0.9281,0.063,0.9339,0.8573);
    transform: matrix(1,0,0,1,0,0);
  }
  5.7% {
    animation-timing-function: cubic-bezier(0.4285,0.3322,0.4777,0.669);
    transform: matrix(0.72,0,0,0.72,0,0);
  }
  10.9% {
    animation-timing-function: cubic-bezier(0.0358,0.084,0.2547,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  20.1% {
    animation-timing-function: cubic-bezier(0.7964,0.1337,0.8635,0.7525);
    transform: matrix(0.5,0,0,0.5,0,0);
  }
  24.9% {
    animation-timing-function: cubic-bezier(0.4857,0.3288,0.4241,0.6751);
    transform: matrix(0.357,0,0,0.357,0,0);
  }
  29.3% {
    animation-timing-function: cubic-bezier(0.069,0.1323,0.299,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  37.1% {
    animation-timing-function: cubic-bezier(0.71,0,0.8669,0.8745);
    transform: matrix(0.25,0,0,0.25,0,0);
  }
  44.9% {
    animation-timing-function: cubic-bezier(0.1195,0.2278,0.3743,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  51.6% {
    animation-timing-function: cubic-bezier(0.7743,0,0.5778,0.8867);
    transform: matrix(0.125,0,0,0.125,0,0);
  }
  58.2% {
    animation-timing-function: cubic-bezier(0.1362,0.3881,0.5196,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  63.9% {
    animation-timing-function: cubic-bezier(0.5363,0,0.7437,1);
    transform: matrix(0.062,0,0,0.062,0,0);
  }
  69.5% {
    animation-timing-function: cubic-bezier(0.1755,0.5617,0.6288,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.3931,0,0.722,1.4447);
    transform: matrix(0.031,0,0,0.031,0,0);
  }
  79.1% {
    animation-timing-function: cubic-bezier(0.2945,0,0.6251,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  83.2% {
    animation-timing-function: cubic-bezier(0.1679,0,0.9959,1.0111);
    transform: matrix(0.016,0,0,0.016,0,0);
  }
  87.3% {
    animation-timing-function: cubic-bezier(0.3158,0,0.639,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3636,0,0.6809,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  94.2% {
    animation-timing-function: cubic-bezier(0.323,0,0.6275,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  100% {
    transform: matrix(0.01,0,0,0.01,0,0);
  }
}
.ld.ld-jump-alt-out {
  animation: ld-jump-alt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0); 
}
@keyframes ld-jump-in
{
  0% {
    animation-timing-function: cubic-bezier(0.6505,0.0695,0.8671,0.7282);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  16.3% {
    animation-timing-function: cubic-bezier(0.4438,0.3294,0.7021,0.6649);
    transform: matrix(0.305,0,0,0.305,0,0);
  }
  29.7% {
    animation-timing-function: cubic-bezier(0.1172,0.239,0.3587,0.9665);
    transform: matrix(0.996,0,0,0.996,0,0);
  }
  50.4% {
    animation-timing-function: cubic-bezier(0.6378,0.0332,0.8686,0.7676);
    transform: matrix(0.6,0,0,0.6,0,0);
  }
  71.1% {
    animation-timing-function: cubic-bezier(0.1677,0.3519,0.4926,1);
    transform: matrix(0.998,0,0,0.998,0,0);
  }
  85.6% {
    animation-timing-function: cubic-bezier(0.5309,0.0683,0.7981,0.6106);
    transform: matrix(0.84,0,0,0.84,0,0);
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
  }
}
.ld.ld-jump-in {
  animation: ld-jump-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.01,0,0,0.01,0,0); 
}
@keyframes ld-jump-out
{
  0% {
    animation-timing-function: cubic-bezier(0.6469,0.0494,0.8696,0.7444);
    transform: matrix(1,0,0,1,0,0);
  }
  16.3% {
    animation-timing-function: cubic-bezier(0.4619,0.3285,0.6826,0.6658);
    transform: matrix(0.695,0,0,0.695,0,0);
  }
  29.7% {
    animation-timing-function: cubic-bezier(0.1007,0.2105,0.3808,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  50.4% {
    animation-timing-function: cubic-bezier(0.6296,0,0.8602,0.7964);
    transform: matrix(0.4,0,0,0.4,0,0);
  }
  71.1% {
    animation-timing-function: cubic-bezier(0.1905,0.3497,0.4786,1);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  85.6% {
    animation-timing-function: cubic-bezier(0.5195,0,0.8129,0.6633);
    transform: matrix(0.16,0,0,0.16,0,0);
  }
  100% {
    transform: matrix(0.01,0,0,0.01,0,0);
  }
}
.ld.ld-jump-out {
  animation: ld-jump-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0); 
}
@keyframes ld-zoom-in
{
  0% {
    animation-timing-function: cubic-bezier(0.0436,0.6939,0.3878,0.8531);
    transform: matrix(0.01,0,0,0.01,0,0);
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
  }
}
.ld.ld-zoom-in {
  animation: ld-zoom-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.01,0,0,0.01,0,0); 
}
@keyframes ld-zoom-out
{
  0% {
    animation-timing-function: cubic-bezier(0.0436,0.6939,0.3878,0.8531);
    transform: matrix(1,0,0,1,0,0);
  }
  100% {
    transform: matrix(0.01,0,0,0.01,0,0);
  }
}
.ld.ld-zoom-out {
  animation: ld-zoom-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0); 
}
@keyframes ld-fade-in
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ld.ld-fade-in {
  animation: ld-fade-in 1s 1 linear; animation-fill-mode: forwards;opacity: 0; 
}
@keyframes ld-fade-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ld.ld-fade-out {
  animation: ld-fade-out 1s 1 linear; animation-fill-mode: forwards;opacity: 1; 
}
@keyframes ld-grow-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(49.75%,0%)  scale(0.005,1);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(31.2%,0%)  scale(0.376,1);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-grow-rtl-in {
  animation: ld-grow-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.005,0,0,1,49.75,0);opacity: 0; 
}
@keyframes ld-grow-rtl-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(49.6%,0%)  scale(0.008,1);
    opacity: 1;
  }
  100% {
    transform: translate(49.75%,0%)  scale(0.005,1);
    opacity: 0;
  }
}
.ld.ld-grow-rtl-out {
  animation: ld-grow-rtl-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-grow-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(-49.75%,0%)  scale(0.005,1);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(-31.2%,0%)  scale(0.376,1);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-grow-ltr-in {
  animation: ld-grow-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.005,0,0,1,-49.75,0);opacity: 0; 
}
@keyframes ld-grow-ltr-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(-49.6%,0%)  scale(0.008,1);
    opacity: 1;
  }
  100% {
    transform: translate(-49.75%,0%)  scale(0.005,1);
    opacity: 0;
  }
}
.ld.ld-grow-ltr-out {
  animation: ld-grow-ltr-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-grow-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,-49.75%)  scale(1,0.005);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,-31.2%)  scale(1,0.376);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-grow-ttb-in {
  animation: ld-grow-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,0.005,0,-49.75);opacity: 0; 
}
@keyframes ld-grow-ttb-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,-49.6%)  scale(1,0.008);
    opacity: 1;
  }
  100% {
    transform: translate(0%,-49.75%)  scale(1,0.005);
    opacity: 0;
  }
}
.ld.ld-grow-ttb-out {
  animation: ld-grow-ttb-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-grow-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,49.75%)  scale(1,0.005);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,31.2%)  scale(1,0.376);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-grow-btt-in {
  animation: ld-grow-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,0.005,0,49.75);opacity: 0; 
}
@keyframes ld-grow-btt-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,49.6%)  scale(1,0.008);
    opacity: 1;
  }
  100% {
    transform: translate(0%,49.75%)  scale(1,0.005);
    opacity: 0;
  }
}
.ld.ld-grow-btt-out {
  animation: ld-grow-btt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-flip-v-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,0%)  scale(1,0.005);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,0%)  scale(1,0.376);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-flip-v-in {
  animation: ld-flip-v-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,0.005,0,0);opacity: 0; 
}
@keyframes ld-flip-v-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,0%)  scale(1,0.008);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,0.005);
    opacity: 0;
  }
}
.ld.ld-flip-v-out {
  animation: ld-flip-v-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-flip-h-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,0%)  scale(0.005,1);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,0%)  scale(0.376,1);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
}
.ld.ld-flip-h-in {
  animation: ld-flip-h-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.005,0,0,1,0,0);opacity: 0; 
}
@keyframes ld-flip-h-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%)  scale(1,1);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,0%)  scale(0.008,1);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%)  scale(0.005,1);
    opacity: 0;
  }
}
.ld.ld-flip-h-out {
  animation: ld-flip-h-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-slide-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(199%,0%);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(124.8%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-rtl-in {
  animation: ld-slide-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,199,0);opacity: 0; 
}
@keyframes ld-slide-rtl-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(198.4%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(199%,0%);
    opacity: 0;
  }
}
.ld.ld-slide-rtl-out {
  animation: ld-slide-rtl-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-slide-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(-199%,0%);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(-124.8%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-ltr-in {
  animation: ld-slide-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,-199,0);opacity: 0; 
}
@keyframes ld-slide-ltr-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(-198.4%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(-199%,0%);
    opacity: 0;
  }
}
.ld.ld-slide-ltr-out {
  animation: ld-slide-ltr-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-slide-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,-199%);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,-124.8%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-ttb-in {
  animation: ld-slide-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,-199);opacity: 0; 
}
@keyframes ld-slide-ttb-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,-198.4%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,-199%);
    opacity: 0;
  }
}
.ld.ld-slide-ttb-out {
  animation: ld-slide-ttb-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-slide-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.1006,0.3357,-0.2396,0.6703);
    transform: translate(0%,199%);
    opacity: 0;
  }
  2% {
    animation-timing-function: cubic-bezier(0.2668,0.6542,0.5372,0.8035);
    transform: translate(0%,124.8%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-btt-in {
  animation: ld-slide-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,199);opacity: 0; 
}
@keyframes ld-slide-btt-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2221,0.7433,0.3066,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  97% {
    animation-timing-function: cubic-bezier(0.3286,0.4108,0.6714,0.5892);
    transform: translate(0%,198.4%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,199%);
    opacity: 0;
  }
}
.ld.ld-slide-btt-out {
  animation: ld-slide-btt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-float-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(14.925%,0%);
    opacity: 0.005;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-float-rtl-in {
  animation: ld-float-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,14.925,0);opacity: 0.005; 
}
@keyframes ld-float-rtl-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(14.925%,0%);
    opacity: 0.005;
  }
}
.ld.ld-float-rtl-out {
  animation: ld-float-rtl-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-float-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(-14.925%,0%);
    opacity: 0.005;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-float-ltr-in {
  animation: ld-float-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,-14.925,0);opacity: 0.005; 
}
@keyframes ld-float-ltr-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(-14.925%,0%);
    opacity: 0.005;
  }
}
.ld.ld-float-ltr-out {
  animation: ld-float-ltr-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-float-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,-14.925%);
    opacity: 0.005;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-float-ttb-in {
  animation: ld-float-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,-14.925);opacity: 0.005; 
}
@keyframes ld-float-ttb-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,-14.925%);
    opacity: 0.005;
  }
}
.ld.ld-float-ttb-out {
  animation: ld-float-ttb-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-float-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,14.925%);
    opacity: 0.005;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-float-btt-in {
  animation: ld-float-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,14.925);opacity: 0.005; 
}
@keyframes ld-float-btt-out
{
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(0%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,14.925%);
    opacity: 0.005;
  }
}
.ld.ld-float-btt-out {
  animation: ld-float-btt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-fall-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
    transform: translate(49.75%,0%);
    opacity: 0;
  }
  31% {
    animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
    transform: translate(2.65%,0%);
    opacity: 1;
  }
  51% {
    animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
    transform: translate(20%,0%);
    opacity: 1;
  }
  72% {
    animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
    transform: translate(1.05%,0%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
    transform: translate(7.9%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-fall-rtl-in {
  animation: ld-fall-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,49.75,0);opacity: 0; 
}
@keyframes ld-fall-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
    transform: translate(-49.75%,0%);
    opacity: 0;
  }
  31% {
    animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
    transform: translate(-2.65%,0%);
    opacity: 1;
  }
  51% {
    animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
    transform: translate(-20%,0%);
    opacity: 1;
  }
  72% {
    animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
    transform: translate(-1.05%,0%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
    transform: translate(-7.9%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-fall-ltr-in {
  animation: ld-fall-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,-49.75,0);opacity: 0; 
}
@keyframes ld-fall-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
    transform: translate(0%,-49.75%);
    opacity: 0;
  }
  31% {
    animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
    transform: translate(0%,-2.65%);
    opacity: 1;
  }
  51% {
    animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
    transform: translate(0%,-20%);
    opacity: 1;
  }
  72% {
    animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
    transform: translate(0%,-1.05%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
    transform: translate(0%,-7.9%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-fall-ttb-in {
  animation: ld-fall-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,-49.75);opacity: 0; 
}
@keyframes ld-fall-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
    transform: translate(0%,49.75%);
    opacity: 0;
  }
  31% {
    animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
    transform: translate(0%,2.65%);
    opacity: 1;
  }
  51% {
    animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
    transform: translate(0%,20%);
    opacity: 1;
  }
  72% {
    animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
    transform: translate(0%,1.05%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
    transform: translate(0%,7.9%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-fall-btt-in {
  animation: ld-fall-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,49.75);opacity: 0; 
}
@keyframes ld-power-off {
  0%, 20%, 100% {
    animation-timing-function: cubic-bezier(0,0.5,0.5,1);
  }
  100% { transform: scale(0,0); opacity: 0}
  99% { transform: scale(0.05,0.05); opacity: 1 }
  0% { transform: scale(1,1); opacity: 1 }
  20% { transform: scale(1,0.1); }
}
.ld.ld-power-off {
  animation: ld-power-off 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-power-on {
  0%, 20%, 100% {
    animation-timing-function: cubic-bezier(0,0.5,0.5,1);
  }
  0% { transform: scale(0,0); opacity: 0}
  1% { transform: scale(0.05,0.05); opacity: 1 }
  100% { transform: scale(1,1); opacity: 1 }
  20% { transform: scale(1,0.1); }
}
.ld.ld-power-on {
  animation: ld-power-on 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0,0,0,0.05,0,0);opacity: 0; 
}
@keyframes ld-rush-ltr-in {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateX(-78.47520861406802%) skewX(30deg); }
30% { transform: translateX(2.5743741577959263%) skewX(-15deg); }
40% { transform: translateX(8.574374157795926%) skewX(-15deg); }
55.00000000000001% { transform: translateX(-4.212879922796667%) skewX(7.5deg); }
70% { transform: translateX(2.097390810087623%) skewX(-3.75deg); }
80% { transform: translateX(0%) skewX(0deg); }
100% { transform: translateX(0%) skewX(0deg); }

  100% { opacity: 1}
}
.ld.ld-rush-ltr-in {
  animation: ld-rush-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: translateX(-78.47520861406802%) skewX(30deg);opacity: 0; 
}
@keyframes ld-rush-rtl-in {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateX(78.47520861406802%) skewX(-30deg); }
30% { transform: translateX(-2.5743741577959263%) skewX(15deg); }
40% { transform: translateX(-8.574374157795926%) skewX(15deg); }
55.00000000000001% { transform: translateX(4.212879922796667%) skewX(-7.5deg); }
70% { transform: translateX(-2.097390810087623%) skewX(3.75deg); }
80% { transform: translateX(0%) skewX(0deg); }
100% { transform: translateX(0%) skewX(0deg); }

  100% { opacity: 1}
}
.ld.ld-rush-rtl-in {
  animation: ld-rush-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: translateX(78.47520861406802%) skewX(-30deg);opacity: 0; 
}
@keyframes ld-rush-ttb-in {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateY(-78.47520861406802%) skewY(30deg); }
30% { transform: translateY(2.5743741577959263%) skewY(-15deg); }
40% { transform: translateY(8.574374157795926%) skewY(-15deg); }
55.00000000000001% { transform: translateY(-4.212879922796667%) skewY(7.5deg); }
70% { transform: translateY(2.097390810087623%) skewY(-3.75deg); }
80% { transform: translateY(0%) skewY(0deg); }
100% { transform: translateY(0%) skewY(0deg); }

  100% { opacity: 1}
}
.ld.ld-rush-ttb-in {
  animation: ld-rush-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: translateY(-78.47520861406802%) skewY(30deg);opacity: 0; 
}
@keyframes ld-rush-btt-in {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateY(78.47520861406802%) skewY(-30deg); }
30% { transform: translateY(-2.5743741577959263%) skewY(15deg); }
40% { transform: translateY(-8.574374157795926%) skewY(15deg); }
55.00000000000001% { transform: translateY(4.212879922796667%) skewY(-7.5deg); }
70% { transform: translateY(-2.097390810087623%) skewY(3.75deg); }
80% { transform: translateY(0%) skewY(0deg); }
100% { transform: translateY(0%) skewY(0deg); }

  100% { opacity: 1}
}
.ld.ld-rush-btt-in {
  animation: ld-rush-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: translateY(78.47520861406802%) skewY(-30deg);opacity: 0; 
}
@keyframes ld-throw-ttb-in
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: translate(0%,-59.12%);
    opacity: 0;
  }
  20.2% {
    animation-timing-function: cubic-bezier(0.0038,0.1689,0.8274,0.8296);
    transform: translate(0%,0.38%);
    opacity: 1;
  }
  21.1% {
    animation-timing-function: cubic-bezier(0.3342,0.3335,0.6426,0.6668);
    transform: translate(0%,1.92%);
    opacity: 1;
  }
  22.4% {
    animation-timing-function: cubic-bezier(0.3229,0.3337,0.6191,0.6673);
    transform: translate(0%,4.12%);
    opacity: 1;
  }
  25.4% {
    animation-timing-function: cubic-bezier(0.2876,0.3345,0.5637,0.6702);
    transform: translate(0%,8.7%);
    opacity: 1;
  }
  31% {
    animation-timing-function: cubic-bezier(0.2726,0.3366,0.5202,0.681);
    transform: translate(0%,15.120000000000001%);
    opacity: 1;
  }
  36.1% {
    animation-timing-function: cubic-bezier(0.1523,0.4021,0.4888,1.2891);
    transform: translate(0%,18.700000000000003%);
    opacity: 1;
  }
  43% {
    animation-timing-function: cubic-bezier(0.4742,0.2001,0.7463,0.6254);
    transform: translate(0%,19.92%);
    opacity: 1;
  }
  45.7% {
    animation-timing-function: cubic-bezier(0.5641,0.7052,0.2846,0.1824);
    transform: translate(0%,19.28%);
    opacity: 1;
  }
  46.2% {
    animation-timing-function: cubic-bezier(0.4515,0.2964,0.7105,0.6734);
    transform: translate(0%,19.14%);
    opacity: 1;
  }
  47.8% {
    animation-timing-function: cubic-bezier(0.8595,1.0744,-0.1261,-0.2284);
    transform: translate(0%,18.36%);
    opacity: 1;
  }
  48.3% {
    animation-timing-function: cubic-bezier(0.4607,0.3205,0.6232,0.6724);
    transform: translate(0%,18.14%);
    opacity: 1;
  }
  50.3% {
    animation-timing-function: cubic-bezier(0.4337,0.3253,0.7015,0.6642);
    transform: translate(0%,16.759999999999998%);
    opacity: 1;
  }
  52% {
    animation-timing-function: cubic-bezier(0.3458,0.3334,0.7014,0.6641);
    transform: translate(0%,15.4%);
    opacity: 1;
  }
  53.5% {
    animation-timing-function: cubic-bezier(0.3638,0.3322,0.715,0.6655);
    transform: translate(0%,14.000000000000002%);
    opacity: 1;
  }
  57.1% {
    animation-timing-function: cubic-bezier(0.343,0.3332,0.6276,0.6676);
    transform: translate(0%,9.86%);
    opacity: 1;
  }
  59% {
    animation-timing-function: cubic-bezier(0.4199,0.3313,0.6271,0.6678);
    transform: translate(0%,7.22%);
    opacity: 1;
  }
  61.1% {
    animation-timing-function: cubic-bezier(0.432,0.3319,0.6016,0.6677);
    transform: translate(0%,3.92%);
    opacity: 1;
  }
  62.9% {
    animation-timing-function: cubic-bezier(0.2788,0.3346,0.6243,0.6678);
    transform: translate(0%,0.64%);
    opacity: 1;
  }
  63.2% {
    animation-timing-function: cubic-bezier(0.2701,0.3361,-0.4907,1.0828);
    transform: translate(0%,0.26%);
    opacity: 1;
  }
  63.4% {
    animation-timing-function: cubic-bezier(0.3122,0.0151,0.9287,0.9241);
    transform: translate(0%,0.08%);
    opacity: 1;
  }
  64% {
    animation-timing-function: cubic-bezier(0.2872,0.3339,0.5797,0.6742);
    transform: translate(0%,0.7000000000000001%);
    opacity: 1;
  }
  65% {
    animation-timing-function: cubic-bezier(0.5101,0.3239,0.489,0.6756);
    transform: translate(0%,1.56%);
    opacity: 1;
  }
  65.4% {
    animation-timing-function: cubic-bezier(0.3492,0.3326,0.6191,0.6735);
    transform: translate(0%,1.98%);
    opacity: 1;
  }
  66.2% {
    animation-timing-function: cubic-bezier(0.4827,0.3218,0.5157,0.6771);
    transform: translate(0%,2.54%);
    opacity: 1;
  }
  66.6% {
    animation-timing-function: cubic-bezier(0.352,0.33,0.6953,0.6667);
    transform: translate(0%,2.9%);
    opacity: 1;
  }
  68.1% {
    animation-timing-function: cubic-bezier(0.3285,0.3135,0.53,0.7009);
    transform: translate(0%,3.8600000000000003%);
    opacity: 1;
  }
  68.4% {
    animation-timing-function: cubic-bezier(0.3211,0.3359,0.5793,0.6857);
    transform: translate(0%,4.08%);
    opacity: 1;
  }
  71% {
    animation-timing-function: cubic-bezier(0.3795,0.2811,0.4509,0.756);
    transform: translate(0%,5.24%);
    opacity: 1;
  }
  71.4% {
    animation-timing-function: cubic-bezier(0.3702,0.2776,0.6171,0.7128);
    transform: translate(0%,5.42%);
    opacity: 1;
  }
  72.6% {
    animation-timing-function: cubic-bezier(0.2791,0.3954,0.6688,0.7654);
    transform: translate(0%,5.74%);
    opacity: 1;
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.2981,-Infinity,0.6838,Infinity);
    transform: translate(0%,5.9799999999999995%);
    opacity: 1;
  }
  76.8% {
    animation-timing-function: cubic-bezier(0.4345,0.2633,0.6934,0.6284);
    transform: translate(0%,5.88%);
    opacity: 1;
  }
  78.8% {
    animation-timing-function: cubic-bezier(0.44,0.2933,0.56,0.7067);
    transform: translate(0%,5.44%);
    opacity: 1;
  }
  79.2% {
    animation-timing-function: cubic-bezier(0.497,0.2945,0.6754,0.6634);
    transform: translate(0%,5.28%);
    opacity: 1;
  }
  81.6% {
    animation-timing-function: cubic-bezier(0.3941,0.3218,0.6466,0.6702);
    transform: translate(0%,4.2%);
    opacity: 1;
  }
  83% {
    animation-timing-function: cubic-bezier(0.5087,0.3192,0.545,0.6788);
    transform: translate(0%,3.4000000000000004%);
    opacity: 1;
  }
  83.4% {
    animation-timing-function: cubic-bezier(0.4265,0.3262,0.6457,0.6688);
    transform: translate(0%,3.06%);
    opacity: 1;
  }
  86% {
    animation-timing-function: cubic-bezier(0.4235,0.3197,0.7128,0.6748);
    transform: translate(0%,1.02%);
    opacity: 1;
  }
  86.7% {
    animation-timing-function: cubic-bezier(0.1008,0.104,0.4254,0.9641);
    transform: translate(0%,0.34%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.0513,-0.0071,0.921,0.9013);
    transform: translate(0%,0.02%);
    opacity: 1;
  }
  88% {
    animation-timing-function: cubic-bezier(0.3812,0.321,0.7332,0.663);
    transform: translate(0%,0.5%);
    opacity: 1;
  }
  88.8% {
    animation-timing-function: cubic-bezier(0.1491,0.4472,0.7385,0.6078);
    transform: translate(0%,0.8799999999999999%);
    opacity: 1;
  }
  89.3% {
    animation-timing-function: cubic-bezier(0.2541,0.3557,0.7498,0.6497);
    transform: translate(0%,1.06%);
    opacity: 1;
  }
  90% {
    animation-timing-function: cubic-bezier(0.2257,0.2873,0.6492,0.7768);
    transform: translate(0%,1.26%);
    opacity: 1;
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3323,0.3625,0.7042,0.6773);
    transform: translate(0%,1.48%);
    opacity: 1;
  }
  91.9% {
    animation-timing-function: cubic-bezier(0.1805,0.3611,0.7487,0.7487);
    transform: translate(0%,1.7000000000000002%);
    opacity: 1;
  }
  96% {
    animation-timing-function: cubic-bezier(0.3649,0.2539,0.7461,0.6467);
    transform: translate(0%,1.52%);
    opacity: 1;
  }
  97.6% {
    animation-timing-function: cubic-bezier(0.3193,0.3311,0.7709,0.6436);
    transform: translate(0%,1.06%);
    opacity: 1;
  }
  99% {
    animation-timing-function: cubic-bezier(0.2739,0.3287,0.7201,0.6641);
    transform: translate(0%,0.52%);
    opacity: 1;
  }
  99.6% {
    animation-timing-function: cubic-bezier(0.2569,0.2802,0.4188,0.7886);
    transform: translate(0%,0.21999999999999997%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-throw-ttb-in {
  animation: ld-throw-ttb-in 1s 1 linear; animation-fill-mode: forwards;transform: translate(0%,-59.127121840165316%);opacity: 0; 
}
@keyframes ld-throw-ltr-in
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: translate(-59.12%,0%);
    opacity: 0;
  }
  20.2% {
    animation-timing-function: cubic-bezier(0.0038,0.1689,0.8274,0.8296);
    transform: translate(0.38%,0%);
    opacity: 1;
  }
  21.1% {
    animation-timing-function: cubic-bezier(0.3342,0.3335,0.6426,0.6668);
    transform: translate(1.92%,0%);
    opacity: 1;
  }
  22.4% {
    animation-timing-function: cubic-bezier(0.3229,0.3337,0.6191,0.6673);
    transform: translate(4.12%,0%);
    opacity: 1;
  }
  25.4% {
    animation-timing-function: cubic-bezier(0.2876,0.3345,0.5637,0.6702);
    transform: translate(8.7%,0%);
    opacity: 1;
  }
  31% {
    animation-timing-function: cubic-bezier(0.2726,0.3366,0.5202,0.681);
    transform: translate(15.120000000000001%,0%);
    opacity: 1;
  }
  36.1% {
    animation-timing-function: cubic-bezier(0.1523,0.4021,0.4888,1.2891);
    transform: translate(18.700000000000003%,0%);
    opacity: 1;
  }
  43% {
    animation-timing-function: cubic-bezier(0.4742,0.2001,0.7463,0.6254);
    transform: translate(19.92%,0%);
    opacity: 1;
  }
  45.7% {
    animation-timing-function: cubic-bezier(0.5641,0.7052,0.2846,0.1824);
    transform: translate(19.28%,0%);
    opacity: 1;
  }
  46.2% {
    animation-timing-function: cubic-bezier(0.4515,0.2964,0.7105,0.6734);
    transform: translate(19.14%,0%);
    opacity: 1;
  }
  47.8% {
    animation-timing-function: cubic-bezier(0.8595,1.0744,-0.1261,-0.2284);
    transform: translate(18.36%,0%);
    opacity: 1;
  }
  48.3% {
    animation-timing-function: cubic-bezier(0.4607,0.3205,0.6232,0.6724);
    transform: translate(18.14%,0%);
    opacity: 1;
  }
  50.3% {
    animation-timing-function: cubic-bezier(0.4337,0.3253,0.7015,0.6642);
    transform: translate(16.759999999999998%,0%);
    opacity: 1;
  }
  52% {
    animation-timing-function: cubic-bezier(0.3458,0.3334,0.7014,0.6641);
    transform: translate(15.4%,0%);
    opacity: 1;
  }
  53.5% {
    animation-timing-function: cubic-bezier(0.3638,0.3322,0.715,0.6655);
    transform: translate(14.000000000000002%,0%);
    opacity: 1;
  }
  57.1% {
    animation-timing-function: cubic-bezier(0.343,0.3332,0.6276,0.6676);
    transform: translate(9.86%,0%);
    opacity: 1;
  }
  59% {
    animation-timing-function: cubic-bezier(0.4199,0.3313,0.6271,0.6678);
    transform: translate(7.22%,0%);
    opacity: 1;
  }
  61.1% {
    animation-timing-function: cubic-bezier(0.432,0.3319,0.6016,0.6677);
    transform: translate(3.92%,0%);
    opacity: 1;
  }
  62.9% {
    animation-timing-function: cubic-bezier(0.2788,0.3346,0.6243,0.6678);
    transform: translate(0.64%,0%);
    opacity: 1;
  }
  63.2% {
    animation-timing-function: cubic-bezier(0.2701,0.3361,-0.4907,1.0828);
    transform: translate(0.26%,0%);
    opacity: 1;
  }
  63.4% {
    animation-timing-function: cubic-bezier(0.3122,0.0151,0.9287,0.9241);
    transform: translate(0.08%,0%);
    opacity: 1;
  }
  64% {
    animation-timing-function: cubic-bezier(0.2872,0.3339,0.5797,0.6742);
    transform: translate(0.7000000000000001%,0%);
    opacity: 1;
  }
  65% {
    animation-timing-function: cubic-bezier(0.5101,0.3239,0.489,0.6756);
    transform: translate(1.56%,0%);
    opacity: 1;
  }
  65.4% {
    animation-timing-function: cubic-bezier(0.3492,0.3326,0.6191,0.6735);
    transform: translate(1.98%,0%);
    opacity: 1;
  }
  66.2% {
    animation-timing-function: cubic-bezier(0.4827,0.3218,0.5157,0.6771);
    transform: translate(2.54%,0%);
    opacity: 1;
  }
  66.6% {
    animation-timing-function: cubic-bezier(0.352,0.33,0.6953,0.6667);
    transform: translate(2.9%,0%);
    opacity: 1;
  }
  68.1% {
    animation-timing-function: cubic-bezier(0.3285,0.3135,0.53,0.7009);
    transform: translate(3.8600000000000003%,0%);
    opacity: 1;
  }
  68.4% {
    animation-timing-function: cubic-bezier(0.3211,0.3359,0.5793,0.6857);
    transform: translate(4.08%,0%);
    opacity: 1;
  }
  71% {
    animation-timing-function: cubic-bezier(0.3795,0.2811,0.4509,0.756);
    transform: translate(5.24%,0%);
    opacity: 1;
  }
  71.4% {
    animation-timing-function: cubic-bezier(0.3702,0.2776,0.6171,0.7128);
    transform: translate(5.42%,0%);
    opacity: 1;
  }
  72.6% {
    animation-timing-function: cubic-bezier(0.2791,0.3954,0.6688,0.7654);
    transform: translate(5.74%,0%);
    opacity: 1;
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.2981,-Infinity,0.6838,Infinity);
    transform: translate(5.9799999999999995%,0%);
    opacity: 1;
  }
  76.8% {
    animation-timing-function: cubic-bezier(0.4345,0.2633,0.6934,0.6284);
    transform: translate(5.88%,0%);
    opacity: 1;
  }
  78.8% {
    animation-timing-function: cubic-bezier(0.44,0.2933,0.56,0.7067);
    transform: translate(5.44%,0%);
    opacity: 1;
  }
  79.2% {
    animation-timing-function: cubic-bezier(0.497,0.2945,0.6754,0.6634);
    transform: translate(5.28%,0%);
    opacity: 1;
  }
  81.6% {
    animation-timing-function: cubic-bezier(0.3941,0.3218,0.6466,0.6702);
    transform: translate(4.2%,0%);
    opacity: 1;
  }
  83% {
    animation-timing-function: cubic-bezier(0.5087,0.3192,0.545,0.6788);
    transform: translate(3.4000000000000004%,0%);
    opacity: 1;
  }
  83.4% {
    animation-timing-function: cubic-bezier(0.4265,0.3262,0.6457,0.6688);
    transform: translate(3.06%,0%);
    opacity: 1;
  }
  86% {
    animation-timing-function: cubic-bezier(0.4235,0.3197,0.7128,0.6748);
    transform: translate(1.02%,0%);
    opacity: 1;
  }
  86.7% {
    animation-timing-function: cubic-bezier(0.1008,0.104,0.4254,0.9641);
    transform: translate(0.34%,0%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.0513,-0.0071,0.921,0.9013);
    transform: translate(0.02%,0%);
    opacity: 1;
  }
  88% {
    animation-timing-function: cubic-bezier(0.3812,0.321,0.7332,0.663);
    transform: translate(0.5%,0%);
    opacity: 1;
  }
  88.8% {
    animation-timing-function: cubic-bezier(0.1491,0.4472,0.7385,0.6078);
    transform: translate(0.8799999999999999%,0%);
    opacity: 1;
  }
  89.3% {
    animation-timing-function: cubic-bezier(0.2541,0.3557,0.7498,0.6497);
    transform: translate(1.06%,0%);
    opacity: 1;
  }
  90% {
    animation-timing-function: cubic-bezier(0.2257,0.2873,0.6492,0.7768);
    transform: translate(1.26%,0%);
    opacity: 1;
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3323,0.3625,0.7042,0.6773);
    transform: translate(1.48%,0%);
    opacity: 1;
  }
  91.9% {
    animation-timing-function: cubic-bezier(0.1805,0.3611,0.7487,0.7487);
    transform: translate(1.7000000000000002%,0%);
    opacity: 1;
  }
  96% {
    animation-timing-function: cubic-bezier(0.3649,0.2539,0.7461,0.6467);
    transform: translate(1.52%,0%);
    opacity: 1;
  }
  97.6% {
    animation-timing-function: cubic-bezier(0.3193,0.3311,0.7709,0.6436);
    transform: translate(1.06%,0%);
    opacity: 1;
  }
  99% {
    animation-timing-function: cubic-bezier(0.2739,0.3287,0.7201,0.6641);
    transform: translate(0.52%,0%);
    opacity: 1;
  }
  99.6% {
    animation-timing-function: cubic-bezier(0.2569,0.2802,0.4188,0.7886);
    transform: translate(0.21999999999999997%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-throw-ltr-in {
  animation: ld-throw-ltr-in 1s 1 linear; animation-fill-mode: forwards;transform: translate(-59.127121840165316%,0%);opacity: 0; 
}
@keyframes ld-throw-rtl-in
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: translate(59.12%,0%);
    opacity: 0;
  }
  20.2% {
    animation-timing-function: cubic-bezier(0.0038,0.1689,0.8274,0.8296);
    transform: translate(-0.38%,0%);
    opacity: 1;
  }
  21.1% {
    animation-timing-function: cubic-bezier(0.3342,0.3335,0.6426,0.6668);
    transform: translate(-1.92%,0%);
    opacity: 1;
  }
  22.4% {
    animation-timing-function: cubic-bezier(0.3229,0.3337,0.6191,0.6673);
    transform: translate(-4.12%,0%);
    opacity: 1;
  }
  25.4% {
    animation-timing-function: cubic-bezier(0.2876,0.3345,0.5637,0.6702);
    transform: translate(-8.7%,0%);
    opacity: 1;
  }
  31% {
    animation-timing-function: cubic-bezier(0.2726,0.3366,0.5202,0.681);
    transform: translate(-15.120000000000001%,0%);
    opacity: 1;
  }
  36.1% {
    animation-timing-function: cubic-bezier(0.1523,0.4021,0.4888,1.2891);
    transform: translate(-18.700000000000003%,0%);
    opacity: 1;
  }
  43% {
    animation-timing-function: cubic-bezier(0.4742,0.2001,0.7463,0.6254);
    transform: translate(-19.92%,0%);
    opacity: 1;
  }
  45.7% {
    animation-timing-function: cubic-bezier(0.5641,0.7052,0.2846,0.1824);
    transform: translate(-19.28%,0%);
    opacity: 1;
  }
  46.2% {
    animation-timing-function: cubic-bezier(0.4515,0.2964,0.7105,0.6734);
    transform: translate(-19.14%,0%);
    opacity: 1;
  }
  47.8% {
    animation-timing-function: cubic-bezier(0.8595,1.0744,-0.1261,-0.2284);
    transform: translate(-18.36%,0%);
    opacity: 1;
  }
  48.3% {
    animation-timing-function: cubic-bezier(0.4607,0.3205,0.6232,0.6724);
    transform: translate(-18.14%,0%);
    opacity: 1;
  }
  50.3% {
    animation-timing-function: cubic-bezier(0.4337,0.3253,0.7015,0.6642);
    transform: translate(-16.759999999999998%,0%);
    opacity: 1;
  }
  52% {
    animation-timing-function: cubic-bezier(0.3458,0.3334,0.7014,0.6641);
    transform: translate(-15.4%,0%);
    opacity: 1;
  }
  53.5% {
    animation-timing-function: cubic-bezier(0.3638,0.3322,0.715,0.6655);
    transform: translate(-14.000000000000002%,0%);
    opacity: 1;
  }
  57.1% {
    animation-timing-function: cubic-bezier(0.343,0.3332,0.6276,0.6676);
    transform: translate(-9.86%,0%);
    opacity: 1;
  }
  59% {
    animation-timing-function: cubic-bezier(0.4199,0.3313,0.6271,0.6678);
    transform: translate(-7.22%,0%);
    opacity: 1;
  }
  61.1% {
    animation-timing-function: cubic-bezier(0.432,0.3319,0.6016,0.6677);
    transform: translate(-3.92%,0%);
    opacity: 1;
  }
  62.9% {
    animation-timing-function: cubic-bezier(0.2788,0.3346,0.6243,0.6678);
    transform: translate(-0.64%,0%);
    opacity: 1;
  }
  63.2% {
    animation-timing-function: cubic-bezier(0.2701,0.3361,-0.4907,1.0828);
    transform: translate(-0.26%,0%);
    opacity: 1;
  }
  63.4% {
    animation-timing-function: cubic-bezier(0.3122,0.0151,0.9287,0.9241);
    transform: translate(-0.08%,0%);
    opacity: 1;
  }
  64% {
    animation-timing-function: cubic-bezier(0.2872,0.3339,0.5797,0.6742);
    transform: translate(-0.7000000000000001%,0%);
    opacity: 1;
  }
  65% {
    animation-timing-function: cubic-bezier(0.5101,0.3239,0.489,0.6756);
    transform: translate(-1.56%,0%);
    opacity: 1;
  }
  65.4% {
    animation-timing-function: cubic-bezier(0.3492,0.3326,0.6191,0.6735);
    transform: translate(-1.98%,0%);
    opacity: 1;
  }
  66.2% {
    animation-timing-function: cubic-bezier(0.4827,0.3218,0.5157,0.6771);
    transform: translate(-2.54%,0%);
    opacity: 1;
  }
  66.6% {
    animation-timing-function: cubic-bezier(0.352,0.33,0.6953,0.6667);
    transform: translate(-2.9%,0%);
    opacity: 1;
  }
  68.1% {
    animation-timing-function: cubic-bezier(0.3285,0.3135,0.53,0.7009);
    transform: translate(-3.8600000000000003%,0%);
    opacity: 1;
  }
  68.4% {
    animation-timing-function: cubic-bezier(0.3211,0.3359,0.5793,0.6857);
    transform: translate(-4.08%,0%);
    opacity: 1;
  }
  71% {
    animation-timing-function: cubic-bezier(0.3795,0.2811,0.4509,0.756);
    transform: translate(-5.24%,0%);
    opacity: 1;
  }
  71.4% {
    animation-timing-function: cubic-bezier(0.3702,0.2776,0.6171,0.7128);
    transform: translate(-5.42%,0%);
    opacity: 1;
  }
  72.6% {
    animation-timing-function: cubic-bezier(0.2791,0.3954,0.6688,0.7654);
    transform: translate(-5.74%,0%);
    opacity: 1;
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.2981,-Infinity,0.6838,Infinity);
    transform: translate(-5.9799999999999995%,0%);
    opacity: 1;
  }
  76.8% {
    animation-timing-function: cubic-bezier(0.4345,0.2633,0.6934,0.6284);
    transform: translate(-5.88%,0%);
    opacity: 1;
  }
  78.8% {
    animation-timing-function: cubic-bezier(0.44,0.2933,0.56,0.7067);
    transform: translate(-5.44%,0%);
    opacity: 1;
  }
  79.2% {
    animation-timing-function: cubic-bezier(0.497,0.2945,0.6754,0.6634);
    transform: translate(-5.28%,0%);
    opacity: 1;
  }
  81.6% {
    animation-timing-function: cubic-bezier(0.3941,0.3218,0.6466,0.6702);
    transform: translate(-4.2%,0%);
    opacity: 1;
  }
  83% {
    animation-timing-function: cubic-bezier(0.5087,0.3192,0.545,0.6788);
    transform: translate(-3.4000000000000004%,0%);
    opacity: 1;
  }
  83.4% {
    animation-timing-function: cubic-bezier(0.4265,0.3262,0.6457,0.6688);
    transform: translate(-3.06%,0%);
    opacity: 1;
  }
  86% {
    animation-timing-function: cubic-bezier(0.4235,0.3197,0.7128,0.6748);
    transform: translate(-1.02%,0%);
    opacity: 1;
  }
  86.7% {
    animation-timing-function: cubic-bezier(0.1008,0.104,0.4254,0.9641);
    transform: translate(-0.34%,0%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.0513,-0.0071,0.921,0.9013);
    transform: translate(-0.02%,0%);
    opacity: 1;
  }
  88% {
    animation-timing-function: cubic-bezier(0.3812,0.321,0.7332,0.663);
    transform: translate(-0.5%,0%);
    opacity: 1;
  }
  88.8% {
    animation-timing-function: cubic-bezier(0.1491,0.4472,0.7385,0.6078);
    transform: translate(-0.8799999999999999%,0%);
    opacity: 1;
  }
  89.3% {
    animation-timing-function: cubic-bezier(0.2541,0.3557,0.7498,0.6497);
    transform: translate(-1.06%,0%);
    opacity: 1;
  }
  90% {
    animation-timing-function: cubic-bezier(0.2257,0.2873,0.6492,0.7768);
    transform: translate(-1.26%,0%);
    opacity: 1;
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3323,0.3625,0.7042,0.6773);
    transform: translate(-1.48%,0%);
    opacity: 1;
  }
  91.9% {
    animation-timing-function: cubic-bezier(0.1805,0.3611,0.7487,0.7487);
    transform: translate(-1.7000000000000002%,0%);
    opacity: 1;
  }
  96% {
    animation-timing-function: cubic-bezier(0.3649,0.2539,0.7461,0.6467);
    transform: translate(-1.52%,0%);
    opacity: 1;
  }
  97.6% {
    animation-timing-function: cubic-bezier(0.3193,0.3311,0.7709,0.6436);
    transform: translate(-1.06%,0%);
    opacity: 1;
  }
  99% {
    animation-timing-function: cubic-bezier(0.2739,0.3287,0.7201,0.6641);
    transform: translate(-0.52%,0%);
    opacity: 1;
  }
  99.6% {
    animation-timing-function: cubic-bezier(0.2569,0.2802,0.4188,0.7886);
    transform: translate(-0.21999999999999997%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-throw-rtl-in {
  animation: ld-throw-rtl-in 1s 1 linear; animation-fill-mode: forwards;transform: translate(59.127121840165316%,0%);opacity: 0; 
}
@keyframes ld-throw-btt-in
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: translate(0%,59.12%);
    opacity: 0;
  }
  20.2% {
    animation-timing-function: cubic-bezier(0.0038,0.1689,0.8274,0.8296);
    transform: translate(0%,-0.38%);
    opacity: 1;
  }
  21.1% {
    animation-timing-function: cubic-bezier(0.3342,0.3335,0.6426,0.6668);
    transform: translate(0%,-1.92%);
    opacity: 1;
  }
  22.4% {
    animation-timing-function: cubic-bezier(0.3229,0.3337,0.6191,0.6673);
    transform: translate(0%,-4.12%);
    opacity: 1;
  }
  25.4% {
    animation-timing-function: cubic-bezier(0.2876,0.3345,0.5637,0.6702);
    transform: translate(0%,-8.7%);
    opacity: 1;
  }
  31% {
    animation-timing-function: cubic-bezier(0.2726,0.3366,0.5202,0.681);
    transform: translate(0%,-15.120000000000001%);
    opacity: 1;
  }
  36.1% {
    animation-timing-function: cubic-bezier(0.1523,0.4021,0.4888,1.2891);
    transform: translate(0%,-18.700000000000003%);
    opacity: 1;
  }
  43% {
    animation-timing-function: cubic-bezier(0.4742,0.2001,0.7463,0.6254);
    transform: translate(0%,-19.92%);
    opacity: 1;
  }
  45.7% {
    animation-timing-function: cubic-bezier(0.5641,0.7052,0.2846,0.1824);
    transform: translate(0%,-19.28%);
    opacity: 1;
  }
  46.2% {
    animation-timing-function: cubic-bezier(0.4515,0.2964,0.7105,0.6734);
    transform: translate(0%,-19.14%);
    opacity: 1;
  }
  47.8% {
    animation-timing-function: cubic-bezier(0.8595,1.0744,-0.1261,-0.2284);
    transform: translate(0%,-18.36%);
    opacity: 1;
  }
  48.3% {
    animation-timing-function: cubic-bezier(0.4607,0.3205,0.6232,0.6724);
    transform: translate(0%,-18.14%);
    opacity: 1;
  }
  50.3% {
    animation-timing-function: cubic-bezier(0.4337,0.3253,0.7015,0.6642);
    transform: translate(0%,-16.759999999999998%);
    opacity: 1;
  }
  52% {
    animation-timing-function: cubic-bezier(0.3458,0.3334,0.7014,0.6641);
    transform: translate(0%,-15.4%);
    opacity: 1;
  }
  53.5% {
    animation-timing-function: cubic-bezier(0.3638,0.3322,0.715,0.6655);
    transform: translate(0%,-14.000000000000002%);
    opacity: 1;
  }
  57.1% {
    animation-timing-function: cubic-bezier(0.343,0.3332,0.6276,0.6676);
    transform: translate(0%,-9.86%);
    opacity: 1;
  }
  59% {
    animation-timing-function: cubic-bezier(0.4199,0.3313,0.6271,0.6678);
    transform: translate(0%,-7.22%);
    opacity: 1;
  }
  61.1% {
    animation-timing-function: cubic-bezier(0.432,0.3319,0.6016,0.6677);
    transform: translate(0%,-3.92%);
    opacity: 1;
  }
  62.9% {
    animation-timing-function: cubic-bezier(0.2788,0.3346,0.6243,0.6678);
    transform: translate(0%,-0.64%);
    opacity: 1;
  }
  63.2% {
    animation-timing-function: cubic-bezier(0.2701,0.3361,-0.4907,1.0828);
    transform: translate(0%,-0.26%);
    opacity: 1;
  }
  63.4% {
    animation-timing-function: cubic-bezier(0.3122,0.0151,0.9287,0.9241);
    transform: translate(0%,-0.08%);
    opacity: 1;
  }
  64% {
    animation-timing-function: cubic-bezier(0.2872,0.3339,0.5797,0.6742);
    transform: translate(0%,-0.7000000000000001%);
    opacity: 1;
  }
  65% {
    animation-timing-function: cubic-bezier(0.5101,0.3239,0.489,0.6756);
    transform: translate(0%,-1.56%);
    opacity: 1;
  }
  65.4% {
    animation-timing-function: cubic-bezier(0.3492,0.3326,0.6191,0.6735);
    transform: translate(0%,-1.98%);
    opacity: 1;
  }
  66.2% {
    animation-timing-function: cubic-bezier(0.4827,0.3218,0.5157,0.6771);
    transform: translate(0%,-2.54%);
    opacity: 1;
  }
  66.6% {
    animation-timing-function: cubic-bezier(0.352,0.33,0.6953,0.6667);
    transform: translate(0%,-2.9%);
    opacity: 1;
  }
  68.1% {
    animation-timing-function: cubic-bezier(0.3285,0.3135,0.53,0.7009);
    transform: translate(0%,-3.8600000000000003%);
    opacity: 1;
  }
  68.4% {
    animation-timing-function: cubic-bezier(0.3211,0.3359,0.5793,0.6857);
    transform: translate(0%,-4.08%);
    opacity: 1;
  }
  71% {
    animation-timing-function: cubic-bezier(0.3795,0.2811,0.4509,0.756);
    transform: translate(0%,-5.24%);
    opacity: 1;
  }
  71.4% {
    animation-timing-function: cubic-bezier(0.3702,0.2776,0.6171,0.7128);
    transform: translate(0%,-5.42%);
    opacity: 1;
  }
  72.6% {
    animation-timing-function: cubic-bezier(0.2791,0.3954,0.6688,0.7654);
    transform: translate(0%,-5.74%);
    opacity: 1;
  }
  74.3% {
    animation-timing-function: cubic-bezier(0.2981,-Infinity,0.6838,Infinity);
    transform: translate(0%,-5.9799999999999995%);
    opacity: 1;
  }
  76.8% {
    animation-timing-function: cubic-bezier(0.4345,0.2633,0.6934,0.6284);
    transform: translate(0%,-5.88%);
    opacity: 1;
  }
  78.8% {
    animation-timing-function: cubic-bezier(0.44,0.2933,0.56,0.7067);
    transform: translate(0%,-5.44%);
    opacity: 1;
  }
  79.2% {
    animation-timing-function: cubic-bezier(0.497,0.2945,0.6754,0.6634);
    transform: translate(0%,-5.28%);
    opacity: 1;
  }
  81.6% {
    animation-timing-function: cubic-bezier(0.3941,0.3218,0.6466,0.6702);
    transform: translate(0%,-4.2%);
    opacity: 1;
  }
  83% {
    animation-timing-function: cubic-bezier(0.5087,0.3192,0.545,0.6788);
    transform: translate(0%,-3.4000000000000004%);
    opacity: 1;
  }
  83.4% {
    animation-timing-function: cubic-bezier(0.4265,0.3262,0.6457,0.6688);
    transform: translate(0%,-3.06%);
    opacity: 1;
  }
  86% {
    animation-timing-function: cubic-bezier(0.4235,0.3197,0.7128,0.6748);
    transform: translate(0%,-1.02%);
    opacity: 1;
  }
  86.7% {
    animation-timing-function: cubic-bezier(0.1008,0.104,0.4254,0.9641);
    transform: translate(0%,-0.34%);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.0513,-0.0071,0.921,0.9013);
    transform: translate(0%,-0.02%);
    opacity: 1;
  }
  88% {
    animation-timing-function: cubic-bezier(0.3812,0.321,0.7332,0.663);
    transform: translate(0%,-0.5%);
    opacity: 1;
  }
  88.8% {
    animation-timing-function: cubic-bezier(0.1491,0.4472,0.7385,0.6078);
    transform: translate(0%,-0.8799999999999999%);
    opacity: 1;
  }
  89.3% {
    animation-timing-function: cubic-bezier(0.2541,0.3557,0.7498,0.6497);
    transform: translate(0%,-1.06%);
    opacity: 1;
  }
  90% {
    animation-timing-function: cubic-bezier(0.2257,0.2873,0.6492,0.7768);
    transform: translate(0%,-1.26%);
    opacity: 1;
  }
  90.7% {
    animation-timing-function: cubic-bezier(0.3323,0.3625,0.7042,0.6773);
    transform: translate(0%,-1.48%);
    opacity: 1;
  }
  91.9% {
    animation-timing-function: cubic-bezier(0.1805,0.3611,0.7487,0.7487);
    transform: translate(0%,-1.7000000000000002%);
    opacity: 1;
  }
  96% {
    animation-timing-function: cubic-bezier(0.3649,0.2539,0.7461,0.6467);
    transform: translate(0%,-1.52%);
    opacity: 1;
  }
  97.6% {
    animation-timing-function: cubic-bezier(0.3193,0.3311,0.7709,0.6436);
    transform: translate(0%,-1.06%);
    opacity: 1;
  }
  99% {
    animation-timing-function: cubic-bezier(0.2739,0.3287,0.7201,0.6641);
    transform: translate(0%,-0.52%);
    opacity: 1;
  }
  99.6% {
    animation-timing-function: cubic-bezier(0.2569,0.2802,0.4188,0.7886);
    transform: translate(0%,-0.21999999999999997%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-throw-btt-in {
  animation: ld-throw-btt-in 1s 1 linear; animation-fill-mode: forwards;transform: translate(0%,59.127121840165316%);opacity: 0; 
}
@keyframes ld-vortex-in {
  0%, 60% { animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7); }
  0% { opacity: 0; transform: rotate(-1800deg) scale(0.3); }
  60% { opacity: 1; transform: rotate(0deg) scale(1); }
  100% { opacity: 1; transform: rotate(0deg) scale(1); }
}
.ld.ld-vortex-in {
  animation: ld-vortex-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(0.3,0,0,0.3,0,0); 
}
@keyframes ld-vortex-out {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7);
    transform: rotate(0deg) scale(1);
    opacity: 1
  }
  100% { transform: rotate(-1800deg) scale(0.3); opacity: 0; }
}
.ld.ld-vortex-out {
  animation: ld-vortex-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}
@keyframes ld-vortex-alt-in {
  0%, 60% { animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7); }
  0% { opacity: 0; transform: rotate(-1800deg) scale(3); }
  60% { opacity: 1; transform: rotate(0deg) scale(1); }
  100% { opacity: 1; transform: rotate(0deg) scale(1); }
}
.ld.ld-vortex-alt-in {
  animation: ld-vortex-alt-in 1s 1 linear; animation-fill-mode: forwards;transform: matrix(3,0,0,3,0,0); 
}
@keyframes ld-vortex-alt-out {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7);
    transform: rotate(0deg) scale(1);
    opacity: 1
  }
  100% { transform: rotate(-1800deg) scale(3); opacity: 0; }
}
.ld.ld-vortex-alt-out {
  animation: ld-vortex-alt-out 1s 1 linear; animation-fill-mode: forwards;transform: matrix(1,0,0,1,0,0);opacity: 1; 
}