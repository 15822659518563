/*
  with old method, spinner will shift when rotating if font-size contains floating point ( such as 22.5px ).
  here we use 2em in width and height, scaling back to 1em with transform: scale(.5) to prevent this effect.
*/
.ld-ball,
.ld-ring,
.ld-hourglass,
.ld-loader,
.ld-cross,
.ld-square,
.ld-pie,
.ld-spinner {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
  box-sizing: content-box;
}
.ld-ball:after,
.ld-ring:after,
.ld-hourglass:after,
.ld-loader:after,
.ld-cross:after,
.ld-square:after,
.ld-pie:after,
.ld-spinner:after {
  content: " ";
  display: block;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  transform-origin: 0em 0em;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
}
.ld-ring:after {
  border-radius: 50%;
  border: 0.3em solid currentColor;
  border-left-color: transparent;
}
.ld-ball:after {
  border-radius: 50%;
  background: currentColor;
}
.ld-hourglass:after {
  border-radius: 50%;
  border: 1em solid currentColor;
  border-left-color: transparent;
  border-right-color: transparent;
}
.ld-cross:after {
  position: absolute;
  height: 0.5em;
  transform: translateZ(0) scale(0.5) translate(0, 0.75em);
  background: currentColor;
}
.ld-cross:before {
  content: " ";
  display: block;
  width: 0.5em;
  height: 2em;
  box-sizing: border-box;
  transform-origin: 0em 0em;
  backface-visibility: hidden;
  position: absolute;
  width: 0.5em;
  transform: translateZ(0) scale(0.5) translate(0.75em, 0);
  background: currentColor;
}
.ld-square:after {
  background: currentColor;
}
.ld-pie:after {
  border-radius: 50%;
  border: 1em solid currentColor;
  border-left-color: transparent;
}
.ld-spinner:after {
  position: absolute;
  width: 0.4em;
  height: 0.4em;
  transform: translateZ(0) scale(0.5) translate(0.8em, 0.8em);
  border-radius: 50%;
  background: none;
  box-shadow: 0 1em 0 currentColor,0 -1em 0 currentColor,1em 0 0 currentColor,-1em 0 0 currentColor,.70710678em .70710678em 0 currentColor,-.70710678em .70710678em 0 currentColor,.70710678em -.70710678em 0 currentColor,-.70710678em -.70710678em 0 currentColor;
}
.ld-loader {
  background-size: cover;
}
.ld { transform-origin: 50% 50%; transform-box: fill-box; }
@keyframes ld-blink {
  0% { opacity: 1; }
  49.75% { opacity: 1; }
  50.25% { opacity: 0; }
  99.5% { opacity: 0; }
  100% { opacity: 1; }
}
.ld.ld-blink {
  animation: ld-blink 1s infinite linear; ; 
}
@keyframes ld-blur {
  0% { filter: blur(0) }
  50% { filter: blur(10%) }
  100% { filter: blur(0) }
}
.ld.ld-blur {
  animation: ld-blur 1s infinite linear; ; 
}
@keyframes ld-beat
{
  0% {
    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);
    transform: scale(1);
  }
  34% {
    animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);
    transform: scale(1.2);
  }
  68% {
    animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);
    transform: scale(1.0268);
  }
  84% {
    animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);
    transform: scale(1.0932);
  }
  100% {
    transform: scale(1);
  }
}
.ld.ld-beat {
  animation: ld-beat 1s infinite linear; ; 
}
@keyframes ld-bounceAlt
{
  0% {
    animation-timing-function: cubic-bezier(0.1348,0.3256,0.2495,0.8687);
    transform: translate(0, 0%);
  }
  51% {
    animation-timing-function: cubic-bezier(0.7426,0.1782,0.8523,0.6514);
    transform: translate(0, -14%);
  }
  100% {
    transform: translate(0, 0%);
  }
}
.ld.ld-bounceAlt {
  animation: ld-bounceAlt 1s infinite linear; ; 
}
@keyframes ld-tick-alt
{
  0% {
    animation-timing-function: cubic-bezier(0.0637,0.1569,-0.0154,0.8727);
    transform: rotate(0deg);
  }
  22% {
    animation-timing-function: cubic-bezier(0.7634,0.2713,0.7818,0.6832);
    transform: rotate(-44.865deg);
  }
  43% {
    animation-timing-function: cubic-bezier(0.0599,0.1266,0.2372,1.029);
    transform: rotate(-7.515000000000001deg);
  }
  56% {
    animation-timing-function: cubic-bezier(0.6353,0.3049,0.6792,0.6671);
    transform: rotate(-25.290000000000003deg);
  }
  68% {
    animation-timing-function: cubic-bezier(0.0513,0.1148,0.3085,1.1548);
    transform: rotate(-5.13deg);
  }
  76% {
    animation-timing-function: cubic-bezier(0.467,0.2881,0.3593,0.7819);
    transform: rotate(-14.175deg);
  }
  83% {
    animation-timing-function: cubic-bezier(0.0632,0.1615,0.3539,1.4565);
    transform: rotate(-3.6deg);
  }
  88% {
    animation-timing-function: cubic-bezier(0.3563,0.3125,0.5387,1.6475);
    transform: rotate(-7.74deg);
  }
  92% {
    animation-timing-function: cubic-bezier(0.1265,0.4155,0.3821,1.8827);
    transform: rotate(-2.6100000000000003deg);
  }
  95% {
    animation-timing-function: cubic-bezier(0.5727,1.3521,0.0229,3.0356);
    transform: rotate(-4.185deg);
  }
  98% {
    animation-timing-function: cubic-bezier(0.4531,-1.3593,0.757,0.0281);
    transform: rotate(-2.565deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.ld.ld-tick-alt {
  animation: ld-tick-alt 1s infinite linear; ; 
}
@keyframes ld-jump
{
  0% {
    animation-timing-function: cubic-bezier(0.0637,0.1569,-0.0154,0.8727);
    transform: translate(0,0%);
  }
  22% {
    animation-timing-function: cubic-bezier(0.7634,0.2713,0.7818,0.6832);
    transform: translate(0,-13.958%);
  }
  43% {
    animation-timing-function: cubic-bezier(0.0599,0.1266,0.2372,1.029);
    transform: translate(0,-2.338%);
  }
  56% {
    animation-timing-function: cubic-bezier(0.6353,0.3049,0.6792,0.6671);
    transform: translate(0,-7.868%);
  }
  68% {
    animation-timing-function: cubic-bezier(0.0513,0.1148,0.3085,1.1548);
    transform: translate(0,-1.596%);
  }
  76% {
    animation-timing-function: cubic-bezier(0.467,0.2881,0.3593,0.7819);
    transform: translate(0,-4.41%);
  }
  83% {
    animation-timing-function: cubic-bezier(0.0632,0.1615,0.3539,1.4565);
    transform: translate(0,-1.12%);
  }
  88% {
    animation-timing-function: cubic-bezier(0.3563,0.3125,0.5387,1.6475);
    transform: translate(0,-2.4080000000000004%);
  }
  92% {
    animation-timing-function: cubic-bezier(0.1265,0.4155,0.3821,1.8827);
    transform: translate(0,-0.812%);
  }
  95% {
    animation-timing-function: cubic-bezier(0.5727,1.3521,0.0229,3.0356);
    transform: translate(0,-1.302%);
  }
  98% {
    animation-timing-function: cubic-bezier(0.4531,-1.3593,0.757,0.0281);
    transform: translate(0,-0.798%);
  }
  100% {
    transform: translate(0,0%);
  }
}
.ld.ld-jump {
  animation: ld-jump 1s infinite linear; ; 
}
@keyframes ld-bounce
{
  0% {
    animation-timing-function: cubic-bezier(0.138,0.2541,0.2177,0.8747);
    transform: translate(0,0%) scaleY(1);
  }
  37% {
    animation-timing-function: cubic-bezier(0.7679,0.1817,0.8401,0.7167);
    transform: translate(0,-39.96%) scaleY(1);
  }
  72.2% {
    animation-timing-function: cubic-bezier(0.1045,0.2026,0.2224,0.9608);
    transform: translate(0,0%) scaleY(1);
  }
  87.2% {
    animation-timing-function: cubic-bezier(0.7463,0.2314,0.8159,0.6941);
    transform: translate(0,19.85%) scaleY(0.603);
  }
  100% {
    transform: translate(0,0%) scaleY(1);
  }
}
.ld.ld-bounce {
  animation: ld-bounce 1s infinite linear; ; 
}
@keyframes ld-clock {
0% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(0deg);
}
8.33333% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(30deg);
}
16.66667% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(60deg);
}
25% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(90deg);
}
33.33333% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(120deg);
}
41.66667% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(150deg);
}
50% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(180deg);
}
58.33333% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(210deg);
}
66.66667% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(240deg);
}
75% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(270deg);
}
83.33333% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(300deg);
}
91.66667% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(330deg);
}
100% {
  animation-timing-function: cubic-bezier(0,0.7,0.30000000000000004,1);
  transform: rotate(360deg);
}
}
.ld.ld-clock {
  animation: ld-clock 12s infinite linear; ; 
}
@keyframes ld-fade
{
  0% {
    animation-timing-function: cubic-bezier(0.2057,0.573,0.3723,0.9184);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ld.ld-fade {
  animation: ld-fade 1s infinite linear; ; 
}
@keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: cubic-bezier(0, 0.4,0.6, 1) }
    0% { transform: scale(1,1) }
   25% { transform: scale(-1,1) }
   50% { transform: scale(-1,-1) }
   75% { transform: scale(1,-1) }
  100% { transform: scale(1,1) }
}
.ld.ld-flip {
  animation: ld-flip 1s infinite linear; ; 
}
@keyframes ld-float {
  0% {
    animation-timing-function: cubic-bezier(0,0.4,0.6,1);
    transform: translate(0,0) scale(0.7);
    box-shadow: 0 0 0 rgba(0,0,0,.3);
  }
  50% {
    animation-timing-function: cubic-bezier(0.4,0,1,0.6);
    transform: translate(0,-15%) scale(1);
    box-shadow: 0 23% 5% -15% rgba(0,0,0,.2)
  }
  100% {
    transform: translate(0,0) scale(0.7);
    box-shadow: 0 0 0 rgba(0,0,0,.3)
  }
} 
.ld.ld-float {
  animation: ld-float 1s infinite linear; ; 
}
@keyframes ld-heartbeat
{
  0% {
    animation-timing-function: cubic-bezier(0.1678,0.6042,0.5465,3.0859);
    transform: scale(1.3);
  }
  30% {
    animation-timing-function: cubic-bezier(0.3206,0.3435,0.6825,0.6598);
    transform: scale(1.15);
  }
  31% {
    animation-timing-function: cubic-bezier(0.2811,0.5061,0.5347,0.909);
    transform: scale(1.1458);
  }
  100% {
    transform: scale(1);
  }
}
.ld.ld-heartbeat {
  animation: ld-heartbeat 1s infinite linear; ; 
}
@keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5,0,1,0.5);
    transform: scale(0) translate(0,0) skewX(0);
    opacity: 1;
  }
  20% {
    transform: scale(1) translate(0,0) skewX(20deg);
  }
  50% {
    animation-timing-function: cubic-bezier(0.6,0,1,0.4);
    transform: scale(1) translate(0,0) skewX(20deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1)  translate(0,200%) skewX(20deg);
    opacity: 0;
  }
} 
.ld.ld-hit {
  animation: ld-hit 2s infinite linear; ; 
}
@keyframes ld-move-ltr
{
  0% {
    transform: translate(0%,0%);
  }
  40% {
    transform: translate(80%,0%);
  }
  49.99999% {
    transform: translate(99.99998%,0%);
  }
  50% {
    transform: translate(-100%,0%);
  }
  50.00001% {
    transform: translate(-99.99998%,0%);
  }
  60% {
    transform: translate(-80%,0%);
  }
  100% {
    transform: translate(0%,0%);
  }
}
.ld.ld-move-ltr {
  animation: ld-move-ltr 1s infinite linear; ; 
}
@keyframes ld-move-rtl
{
  0% {
    transform: translate(0%,0%);
  }
  40% {
    transform: translate(-80%,0%);
  }
  49.99999% {
    transform: translate(-99.99998%,0%);
  }
  50% {
    transform: translate(100%,0%);
  }
  50.00001% {
    transform: translate(99.99998%,0%);
  }
  60% {
    transform: translate(80%,0%);
  }
  100% {
    transform: translate(0%,0%);
  }
}
.ld.ld-move-rtl {
  animation: ld-move-rtl 1s infinite linear; ; 
}
@keyframes ld-move-ttb
{
  0% {
    transform: translate(0%,0%);
  }
  40% {
    transform: translate(0%,80%);
  }
  49.99999% {
    transform: translate(0%,99.99998%);
  }
  50% {
    transform: translate(0%,-100%);
  }
  50.00001% {
    transform: translate(0%,-99.99998%);
  }
  60% {
    transform: translate(0%,-80%);
  }
  100% {
    transform: translate(0%,0%);
  }
}
.ld.ld-move-ttb {
  animation: ld-move-ttb 1s infinite linear; ; 
}
@keyframes ld-move-btt
{
  0% {
    transform: translate(0%,0%);
  }
  40% {
    transform: translate(0%,-80%);
  }
  49.99999% {
    transform: translate(0%,-99.99998%);
  }
  50% {
    transform: translate(0%,100%);
  }
  50.00001% {
    transform: translate(0%,99.99998%);
  }
  60% {
    transform: translate(0%,80%);
  }
  100% {
    transform: translate(0%,0%);
  }
}
.ld.ld-move-btt {
  animation: ld-move-btt 1s infinite linear; ; 
}
@keyframes ld-move-fade-ltr
{
  0% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  40% {
    transform: translate(80%,0%);
    opacity: 1;
  }
  49.99999% {
    transform: translate(99.99998%,0%);
    opacity: 0;
  }
  50% {
    transform: translate(-100%,0%);
    opacity: 0;
  }
  50.00001% {
    transform: translate(-99.99998%,0%);
    opacity: 0;
  }
  60% {
    transform: translate(-80%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-move-fade-ltr {
  animation: ld-move-fade-ltr 1s infinite linear; ; 
}
@keyframes ld-move-fade-rtl
{
  0% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  40% {
    transform: translate(-80%,0%);
    opacity: 1;
  }
  49.99999% {
    transform: translate(-99.99998%,0%);
    opacity: 0;
  }
  50% {
    transform: translate(100%,0%);
    opacity: 0;
  }
  50.00001% {
    transform: translate(99.99998%,0%);
    opacity: 0;
  }
  60% {
    transform: translate(80%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-move-fade-rtl {
  animation: ld-move-fade-rtl 1s infinite linear; ; 
}
@keyframes ld-move-fade-ttb
{
  0% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  40% {
    transform: translate(0%,80%);
    opacity: 1;
  }
  49.99999% {
    transform: translate(0%,99.99998%);
    opacity: 0;
  }
  50% {
    transform: translate(0%,-100%);
    opacity: 0;
  }
  50.00001% {
    transform: translate(0%,-99.99998%);
    opacity: 0;
  }
  60% {
    transform: translate(0%,-80%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-move-fade-ttb {
  animation: ld-move-fade-ttb 1s infinite linear; ; 
}
@keyframes ld-move-fade-btt
{
  0% {
    transform: translate(0%,0%);
    opacity: 1;
  }
  40% {
    transform: translate(0%,-80%);
    opacity: 1;
  }
  49.99999% {
    transform: translate(0%,-99.99998%);
    opacity: 0;
  }
  50% {
    transform: translate(0%,100%);
    opacity: 0;
  }
  50.00001% {
    transform: translate(0%,99.99998%);
    opacity: 0;
  }
  60% {
    transform: translate(0%,80%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-move-fade-btt {
  animation: ld-move-fade-btt 1s infinite linear; ; 
}
 @keyframes ld-orbit {  0% {
animation-timing-function: linear;
transform: translate(0%,-60%) rotate(0deg) } 
 8.33333% {
animation-timing-function: linear;
transform: translate(30%,-51.96152%) rotate(30deg) } 
 16.66667% {
animation-timing-function: linear;
transform: translate(51.96152%,-30%) rotate(60deg) } 
 25% {
animation-timing-function: linear;
transform: translate(60%,0%) rotate(90deg) } 
 33.33333% {
animation-timing-function: linear;
transform: translate(51.96152%,30%) rotate(120deg) } 
 41.66667% {
animation-timing-function: linear;
transform: translate(30%,51.96152%) rotate(150deg) } 
 50% {
animation-timing-function: linear;
transform: translate(0%,60%) rotate(180deg) } 
 58.33333% {
animation-timing-function: linear;
transform: translate(-30%,51.96152%) rotate(210deg) } 
 66.66667% {
animation-timing-function: linear;
transform: translate(-51.96152%,30%) rotate(240deg) } 
 75% {
animation-timing-function: linear;
transform: translate(-60%,0%) rotate(270deg) } 
 83.33333% {
animation-timing-function: linear;
transform: translate(-51.96152%,-30%) rotate(300deg) } 
 91.66667% {
animation-timing-function: linear;
transform: translate(-30%,-51.96152%) rotate(330deg) } 
 100% {
animation-timing-function: linear;
transform: translate(0%,-60%) rotate(360deg) }  } 
.ld.ld-orbit {
  animation: ld-orbit 1s infinite linear; ; 
}
@keyframes ld-breath
{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: scale(0.9099999999999999);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    transform: scale(1.02994);
  }
  100% {
    transform: scale(0.9099999999999999);
  }
}
.ld.ld-breath {
  animation: ld-breath 1s infinite linear; ; 
}
@keyframes ld-dim
{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    opacity: 0;
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    opacity: 0.9995;
  }
  100% {
    opacity: 0;
  }
}
.ld.ld-dim {
  animation: ld-dim 1s infinite linear; ; 
}
@keyframes ld-metronome
{
  0% {
    animation-timing-function: cubic-bezier(0.7806,0.0715,0.8998,0.731);
    transform: translate(-10%) rotate(-20deg);
  }
  17.5% {
    animation-timing-function: cubic-bezier(0.484,0.3308,0.6853,0.6667);
    transform: translate(-6.18%) rotate(-12.36deg);
  }
  27.6% {
    animation-timing-function: cubic-bezier(0.0676,0.1836,0.0518,0.9433);
    transform: translate(2.48%) rotate(4.96deg);
  }
  50.1% {
    animation-timing-function: cubic-bezier(0.7773,0.0708,0.9008,0.735);
    transform: translate(10%) rotate(20deg);
  }
  67.6% {
    animation-timing-function: cubic-bezier(0.4888,0.331,0.6153,0.6674);
    transform: translate(6.16%) rotate(12.32deg);
  }
  80% {
    animation-timing-function: cubic-bezier(0.0801,0.2206,0.1357,0.9363);
    transform: translate(-4.57%) rotate(-9.14deg);
  }
  100% {
    transform: translate(-10%) rotate(-20deg);
  }
}
.ld.ld-metronome {
  animation: ld-metronome 1s infinite linear; ; 
}
@keyframes ld-swing
{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: rotate(-30deg);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    transform: rotate(29.97deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
.ld.ld-swing {
  animation: ld-swing 1s infinite linear; ; 
}
@keyframes ld-wander-v
{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: translate(0,-10%);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    transform: translate(0,9.99%);
  }
  100% {
    transform: translate(0,-10%);
  }
}
.ld.ld-wander-v {
  animation: ld-wander-v 1s infinite linear; ; 
}
@keyframes ld-wander-h
{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: translate(-10%,0);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
    transform: translate(9.99%,0);
  }
  100% {
    transform: translate(-10%,0);
  }
}
.ld.ld-wander-h,.ld.ld-wander {
  animation: ld-wander-h 1s infinite linear; ; 
}
@keyframes ld-pulse
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.3124,0.6668);
    transform: scale(0.85);
  }
  0.5% {
    animation-timing-function: cubic-bezier(0.0233,-0.3865,0.6667,0.6667);
    transform: scale(1.141);
  }
  1.5% {
    animation-timing-function: cubic-bezier(0.2893,0.354,0.6158,0.6958);
    transform: scale(1.124);
  }
  11% {
    animation-timing-function: cubic-bezier(0.2861,0.4196,0.6215,0.7476);
    transform: scale(0.992);
  }
  25% {
    animation-timing-function: cubic-bezier(0.0793,0.2627,0.9972,1.5511);
    transform: scale(0.887);
  }
  49.5% {
    animation-timing-function: cubic-bezier(0.6664,0.3332,0.6667,0.6667);
    transform: scale(0.85);
  }
  50% {
    animation-timing-function: cubic-bezier(0,0.3522,1,0.6686);
    transform: scale(1.1500000000000001);
  }
  51% {
    animation-timing-function: cubic-bezier(0.2668,0.4036,0.554,0.7657);
    transform: scale(1.1320000000000001);
  }
  73.5% {
    animation-timing-function: cubic-bezier(0.2997,1.0028,0.6671,1);
    transform: scale(0.894);
  }
  100% {
    transform: scale(0.85);
  }
}
.ld.ld-pulse {
  animation: ld-pulse 1s infinite linear; ; 
}
@keyframes ld-jingle
{
  0% {
    animation-timing-function: cubic-bezier(0.146,0.2111,0.5902,1.3204);
    transform: rotate(0deg);
  }
  11% {
    animation-timing-function: cubic-bezier(0.1079,0.1992,-0.6462,0.828);
    transform: rotate(7.61deg);
  }
  23% {
    animation-timing-function: cubic-bezier(0.0504,0.0951,0.0163,0.9677);
    transform: rotate(-5.789999999999999deg);
  }
  36% {
    animation-timing-function: cubic-bezier(0.0475,0.0921,0.3134,1.0455);
    transform: rotate(3.35deg);
  }
  49% {
    animation-timing-function: cubic-bezier(0.0789,0.1565,0.3413,1.0972);
    transform: rotate(-1.9300000000000002deg);
  }
  62% {
    animation-timing-function: cubic-bezier(0.141,0.2885,0.406,1.1519);
    transform: rotate(1.12deg);
  }
  75% {
    animation-timing-function: cubic-bezier(0.226,0.4698,0.5031,1.1722);
    transform: rotate(-0.64deg);
  }
  88% {
    animation-timing-function: cubic-bezier(0.3121,0.5521,0.5655,0.8997);
    transform: rotate(0.37deg);
  }
  100% {
    transform: rotate(-0.28deg);
  }
}
.ld.ld-jingle {
  animation: ld-jingle 1s infinite linear; ; transform-origin: 50% 0%
}
@keyframes ld-rubber-v
{
  0% {
    animation-timing-function: cubic-bezier(0.1858,0.2841,0.5834,1.4615);
    transform: scaleY(1);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0467,0.1102,0.4803,1.4719);
    transform: scaleY(1.1354);
  }
  41% {
    animation-timing-function: cubic-bezier(0.0469,0.1108,0.4775,1.4732);
    transform: scaleY(0.9052);
  }
  51% {
    animation-timing-function: cubic-bezier(0.0478,0.1131,0.4723,1.4819);
    transform: scaleY(1.0664);
  }
  61% {
    animation-timing-function: cubic-bezier(0.0506,0.1192,0.4639,1.4818);
    transform: scaleY(0.9536);
  }
  71% {
    animation-timing-function: cubic-bezier(0.0581,0.1374,0.457,1.4901);
    transform: scaleY(1.0326);
  }
  81% {
    animation-timing-function: cubic-bezier(0.0765,0.1813,0.4542,1.4923);
    transform: scaleY(0.9772);
  }
  91% {
    animation-timing-function: cubic-bezier(0.1747,0.3181,0.341,0.878);
    transform: scaleY(1.016);
  }
  100% {
    transform: scaleY(0.9836);
  }
}
.ld.ld-rubber-v {
  animation: ld-rubber-v 1s infinite linear; ; 
}
@keyframes ld-rubber-h
{
  0% {
    animation-timing-function: cubic-bezier(0.1858,0.2841,0.5834,1.4615);
    transform: scaleX(1);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0467,0.1102,0.4803,1.4719);
    transform: scaleX(1.1354);
  }
  41% {
    animation-timing-function: cubic-bezier(0.0469,0.1108,0.4775,1.4732);
    transform: scaleX(0.9052);
  }
  51% {
    animation-timing-function: cubic-bezier(0.0478,0.1131,0.4723,1.4819);
    transform: scaleX(1.0664);
  }
  61% {
    animation-timing-function: cubic-bezier(0.0506,0.1192,0.4639,1.4818);
    transform: scaleX(0.9536);
  }
  71% {
    animation-timing-function: cubic-bezier(0.0581,0.1374,0.457,1.4901);
    transform: scaleX(1.0326);
  }
  81% {
    animation-timing-function: cubic-bezier(0.0765,0.1813,0.4542,1.4923);
    transform: scaleX(0.9772);
  }
  91% {
    animation-timing-function: cubic-bezier(0.1747,0.3181,0.341,0.878);
    transform: scaleX(1.016);
  }
  100% {
    transform: scaleX(0.9836);
  }
}
.ld.ld-rubber-h,.ld.ld-rubber {
  animation: ld-rubber-h 1s infinite linear; ; 
}
@keyframes ld-shake-v
{
  0% {
    animation-timing-function: cubic-bezier(0.1441,0.1912,0.6583,1.1029);
    transform: translate(0,0%);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0667,0.1419,0.6667,1.1415);
    transform: translate(0,7.800000000000001%);
  }
  45% {
    animation-timing-function: cubic-bezier(0.0542,0.1151,0.5697,1.181);
    transform: translate(0,-4.680000000000001%);
  }
  59% {
    animation-timing-function: cubic-bezier(0.0497,0.1058,0.4541,1.231);
    transform: translate(0,2.8100000000000005%);
  }
  73% {
    animation-timing-function: cubic-bezier(0.0808,0.1711,0.4109,1.2519);
    transform: translate(0,-1.6800000000000002%);
  }
  87% {
    animation-timing-function: cubic-bezier(0.2073,0.3705,0.4064,0.8839);
    transform: translate(0,1.01%);
  }
  100% {
    transform: translate(0,-0.78%);
  }
}
.ld.ld-shake-v {
  animation: ld-shake-v 1s infinite linear; ; 
}
@keyframes ld-shake-h
{
  0% {
    animation-timing-function: cubic-bezier(0.1515,0.2047,0.6562,1.1369);
    transform: translate(0%,0);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0628,0.1361,0.6012,1.2083);
    transform: translate(7.66%,0);
  }
  45% {
    animation-timing-function: cubic-bezier(0.0579,0.1251,0.5661,1.2263);
    transform: translate(-5.36%,0);
  }
  59% {
    animation-timing-function: cubic-bezier(0.0523,0.113,0.5181,1.2493);
    transform: translate(3.75%,0);
  }
  73% {
    animation-timing-function: cubic-bezier(0.0513,0.1113,0.4632,1.2762);
    transform: translate(-2.63%,0);
  }
  87% {
    animation-timing-function: cubic-bezier(0.1502,0.2709,0.2303,0.8469);
    transform: translate(1.8399999999999999%,0);
  }
  100% {
    transform: translate(-1.6800000000000002%,0);
  }
}
.ld.ld-shake-h,.ld.ld-shake {
  animation: ld-shake-h 1s infinite linear; ; 
}
@keyframes ld-tick
{
  0% {
    animation-timing-function: cubic-bezier(0.1858,0.2841,0.5834,1.4615);
    transform: rotate(0deg);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0467,0.1102,0.4803,1.4719);
    transform: rotate(13.540000000000001deg);
  }
  41% {
    animation-timing-function: cubic-bezier(0.0469,0.1108,0.4775,1.4732);
    transform: rotate(-9.48deg);
  }
  51% {
    animation-timing-function: cubic-bezier(0.0478,0.1131,0.4723,1.4819);
    transform: rotate(6.640000000000001deg);
  }
  61% {
    animation-timing-function: cubic-bezier(0.0506,0.1192,0.4639,1.4818);
    transform: rotate(-4.640000000000001deg);
  }
  71% {
    animation-timing-function: cubic-bezier(0.0581,0.1374,0.457,1.4901);
    transform: rotate(3.2600000000000002deg);
  }
  81% {
    animation-timing-function: cubic-bezier(0.0765,0.1813,0.4542,1.4923);
    transform: rotate(-2.2800000000000002deg);
  }
  91% {
    animation-timing-function: cubic-bezier(0.1747,0.3181,0.341,0.878);
    transform: rotate(1.6deg);
  }
  100% {
    transform: rotate(-1.6400000000000001deg);
  }
}
.ld.ld-tick {
  animation: ld-tick 1s infinite linear; ; 
}
@keyframes ld-smash
{
  0% {
    animation-timing-function: cubic-bezier(0.3385,0.332,0.6667,0.6667);
    transform: rotate(0deg);
  }
  40.8% {
    animation-timing-function: cubic-bezier(0.3316,0.3338,0.5714,1.3045);
    transform: rotate(24.48deg);
  }
  48.5% {
    animation-timing-function: cubic-bezier(0.9673,0.2776,0.6667,0.6667);
    transform: rotate(29.07deg);
  }
  51% {
    animation-timing-function: cubic-bezier(0.1933,0.2947,-0.0572,0.7191);
    transform: rotate(25.38deg);
  }
  60.8% {
    animation-timing-function: cubic-bezier(0.0583,2.8507,0.8558,1);
    transform: rotate(0.54deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.ld.ld-smash {
  animation: ld-smash 1s infinite linear; ; 
}
@keyframes ld-jelly-alt
{
  0% {
    animation-timing-function: cubic-bezier(0.1858,0.2841,0.5834,1.4615);
    transform: skewX(0deg);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0467,0.1102,0.4803,1.4719);
    transform: skewX(6.7700000000000005deg);
  }
  41% {
    animation-timing-function: cubic-bezier(0.0469,0.1108,0.4775,1.4732);
    transform: skewX(-4.74deg);
  }
  51% {
    animation-timing-function: cubic-bezier(0.0478,0.1131,0.4723,1.4819);
    transform: skewX(3.3200000000000003deg);
  }
  61% {
    animation-timing-function: cubic-bezier(0.0506,0.1192,0.4639,1.4818);
    transform: skewX(-2.3200000000000003deg);
  }
  71% {
    animation-timing-function: cubic-bezier(0.0581,0.1374,0.457,1.4901);
    transform: skewX(1.6300000000000001deg);
  }
  81% {
    animation-timing-function: cubic-bezier(0.0765,0.1813,0.4542,1.4923);
    transform: skewX(-1.1400000000000001deg);
  }
  91% {
    animation-timing-function: cubic-bezier(0.1747,0.3181,0.341,0.878);
    transform: skewX(0.8deg);
  }
  100% {
    transform: skewX(-0.8200000000000001deg);
  }
}
.ld.ld-jelly-alt {
  animation: ld-jelly-alt 1s infinite linear; ; 
}
@keyframes ld-jelly
{
  0% {
    animation-timing-function: cubic-bezier(0.1441,0.1912,0.6583,1.1029);
    transform: translate(0%,0) skewX(0deg);
  }
  31% {
    animation-timing-function: cubic-bezier(0.0667,0.1419,0.6667,1.1415);
    transform: translate(-7.800000000000001%,0) skewX(7.800000000000001deg);
  }
  45% {
    animation-timing-function: cubic-bezier(0.0542,0.1151,0.5697,1.181);
    transform: translate(4.680000000000001%,0) skewX(-4.680000000000001deg);
  }
  59% {
    animation-timing-function: cubic-bezier(0.0497,0.1058,0.4541,1.231);
    transform: translate(-2.8100000000000005%,0) skewX(2.8100000000000005deg);
  }
  73% {
    animation-timing-function: cubic-bezier(0.0808,0.1711,0.4109,1.2519);
    transform: translate(1.6800000000000002%,0) skewX(-1.6800000000000002deg);
  }
  87% {
    animation-timing-function: cubic-bezier(0.2073,0.3705,0.4064,0.8839);
    transform: translate(-1.01%,0) skewX(1.01deg);
  }
  100% {
    transform: translate(0.78%,0) skewX(-0.78deg);
  }
}
.ld.ld-jelly {
  animation: ld-jelly 1s infinite linear; ; 
}
@keyframes ld-damage
{
  0% {
    animation-timing-function: cubic-bezier(0.1916,0.3481,0.5313,2.0622);
    opacity: 1;
  }
  21% {
    animation-timing-function: cubic-bezier(0.0461,0.1237,0.4559,1.8579);
    opacity: 0.42200000000000004;
  }
  29% {
    animation-timing-function: cubic-bezier(0.0468,0.1254,0.4564,1.8559);
    opacity: 1.462;
  }
  37% {
    animation-timing-function: cubic-bezier(0.0479,0.1283,0.457,1.856);
    opacity: 0.63;
  }
  45% {
    animation-timing-function: cubic-bezier(0.0487,0.1306,0.457,1.8506);
    opacity: 1.296;
  }
  53% {
    animation-timing-function: cubic-bezier(0.0515,0.1383,0.4591,1.8571);
    opacity: 0.763;
  }
  61% {
    animation-timing-function: cubic-bezier(0.0556,0.1484,0.4608,1.846);
    opacity: 1.189;
  }
  69% {
    animation-timing-function: cubic-bezier(0.0595,0.1602,0.4632,1.8456);
    opacity: 0.848;
  }
  77% {
    animation-timing-function: cubic-bezier(0.0689,0.1849,0.4698,1.8391);
    opacity: 1.121;
  }
  85% {
    animation-timing-function: cubic-bezier(0.0794,0.2133,0.4765,1.8226);
    opacity: 0.903;
  }
  93% {
    animation-timing-function: cubic-bezier(0.1486,0.2692,0.2335,0.855);
    opacity: 1.078;
  }
  100% {
    opacity: 0.893;
  }
}
.ld.ld-damage {
  animation: ld-damage 1s infinite linear; ; 
}
@keyframes ld-rush-ltr {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateX(-318.47520861406804%) skewX(30deg); }
25% { transform: translateX(-21.425625842204074%) skewX(-15deg); }
33% { transform: translateX(8.574374157795926%) skewX(-15deg); }
44% { transform: translateX(-4.212879922796667%) skewX(7.5deg); }
55.00000000000001% { transform: translateX(2.097390810087623%) skewX(-3.75deg); }
66% { transform: translateX(0%) skewX(0deg); }
80% { transform: translateX(0%) skewX(0deg); }
100% { transform: translateX(300%) skewX(30deg); }

  100% { opacity: 1}
}
.ld.ld-rush-ltr {
  animation: ld-rush-ltr 1s infinite linear; ; 
}
@keyframes ld-rush-rtl {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateX(318.47520861406804%) skewX(-30deg); }
25% { transform: translateX(21.425625842204074%) skewX(15deg); }
33% { transform: translateX(-8.574374157795926%) skewX(15deg); }
44% { transform: translateX(4.212879922796667%) skewX(-7.5deg); }
55.00000000000001% { transform: translateX(-2.097390810087623%) skewX(3.75deg); }
66% { transform: translateX(0%) skewX(0deg); }
80% { transform: translateX(0%) skewX(0deg); }
100% { transform: translateX(-300%) skewX(-30deg); }

  100% { opacity: 1}
}
.ld.ld-rush-rtl {
  animation: ld-rush-rtl 1s infinite linear; ; 
}
@keyframes ld-rush-ttb {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateY(-78.47520861406802%) skewY(30deg); }
25% { transform: translateY(2.5743741577959263%) skewY(-15deg); }
33% { transform: translateY(8.574374157795926%) skewY(-15deg); }
44% { transform: translateY(-4.212879922796667%) skewY(7.5deg); }
55.00000000000001% { transform: translateY(2.097390810087623%) skewY(-3.75deg); }
66% { transform: translateY(0%) skewY(0deg); }
80% { transform: translateY(0%) skewY(0deg); }
100% { transform: translateY(60%) skewY(30deg); }

  100% { opacity: 1}
}
.ld.ld-rush-ttb {
  animation: ld-rush-ttb 1s infinite linear; ; 
}
@keyframes ld-rush-btt {
  0% { animation-timing-function: cubic-bezier(0,0.5,0.5,1); opacity: 0 }
  5% { opacity: 1}
  0% { transform: translateY(318.47520861406804%) skewY(-30deg); }
25% { transform: translateY(21.425625842204074%) skewY(15deg); }
33% { transform: translateY(-8.574374157795926%) skewY(15deg); }
44% { transform: translateY(4.212879922796667%) skewY(-7.5deg); }
55.00000000000001% { transform: translateY(-2.097390810087623%) skewY(3.75deg); }
66% { transform: translateY(0%) skewY(0deg); }
80% { transform: translateY(0%) skewY(0deg); }
100% { transform: translateY(-300%) skewY(-30deg); }

  100% { opacity: 1}
}
.ld.ld-rush-btt {
  animation: ld-rush-btt 1s infinite linear; ; 
}
@keyframes ld-skew {
  0%, 50%, 100% { animation-timing-function: cubic-bezier(0.4,0,1,0.6) }
  25%, 75% {      animation-timing-function: cubic-bezier(0,0.4,0.6,1) }
    0% { transform: skewX(20deg) scale(1) }
   25% { transform: skewX(0deg) scale(0.9) }
   50% { transform: skewX(-20deg) scale(1) }
   75% { transform: skewX(0deg) scale(0.9) }
  100% { transform: skewX(20deg) scale(1) }
}
.ld.ld-skew {
  animation: ld-skew 1s infinite linear; ; 
}
@keyframes ld-skew-alt {
  0%, 50%, 100% { animation-timing-function: cubic-bezier(0.4,0,1,0.6) }
  25%, 75% {      animation-timing-function: cubic-bezier(0,0.4,0.6,1) }
    0% { transform: skewY(20deg) scale(1) }
   25% { transform: skewY(0deg) scale(0.9) }
   50% { transform: skewY(-20deg) scale(1) }
   75% { transform: skewY(0deg) scale(0.9) }
  100% { transform: skewY(20deg) scale(1) }
}
.ld.ld-skew-alt {
  animation: ld-skew-alt 1s infinite linear; ; 
}
@keyframes ld-slide-ltr
{
  0% {
    animation-timing-function: cubic-bezier(0.4652,0.1051,0.774,0.6426);
    transform: translate(0%,0%);
    opacity: 1;
  }
  22.5% {
    animation-timing-function: cubic-bezier(0.4142,0.3131,0.7623,0.6513);
    transform: translate(47.8%,0%);
    opacity: 1;
  }
  45% {
    animation-timing-function: cubic-bezier(0.3615,0.331,0.9646,1.3461);
    transform: translate(147.8%,0%);
    opacity: 1;
  }
  47.5% {
    animation-timing-function: cubic-bezier(0.7006,0.3332,0.6667,0.6667);
    transform: translate(165.6%,0%);
    opacity: 0;
  }
  50% {
    animation-timing-function: cubic-bezier(0.1604,0.3176,-0.0218,0.6965);
    transform: translate(-200%,0%);
    opacity: 0;
  }
  51% {
    animation-timing-function: cubic-bezier(0.1983,0.3529,0.5263,0.6945);
    transform: translate(-179.8%,0%);
    opacity: 0;
  }
  80.4% {
    animation-timing-function: cubic-bezier(0.2342,0.3708,0.5422,0.9065);
    transform: translate(-38.4%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-ltr {
  animation: ld-slide-ltr 1s infinite linear; ; 
}
@keyframes ld-slide-rtl
{
  0% {
    animation-timing-function: cubic-bezier(0.4652,0.1051,0.774,0.6426);
    transform: translate(0%,0%);
    opacity: 1;
  }
  22.5% {
    animation-timing-function: cubic-bezier(0.4142,0.3131,0.7623,0.6513);
    transform: translate(-47.8%,0%);
    opacity: 1;
  }
  45% {
    animation-timing-function: cubic-bezier(0.3615,0.331,0.9646,1.3461);
    transform: translate(-147.8%,0%);
    opacity: 1;
  }
  47.5% {
    animation-timing-function: cubic-bezier(0.7006,0.3332,0.6667,0.6667);
    transform: translate(-165.6%,0%);
    opacity: 0;
  }
  50% {
    animation-timing-function: cubic-bezier(0.1604,0.3176,-0.0218,0.6965);
    transform: translate(200%,0%);
    opacity: 0;
  }
  51% {
    animation-timing-function: cubic-bezier(0.1983,0.3529,0.5263,0.6945);
    transform: translate(179.8%,0%);
    opacity: 0;
  }
  80.4% {
    animation-timing-function: cubic-bezier(0.2342,0.3708,0.5422,0.9065);
    transform: translate(38.4%,0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-rtl {
  animation: ld-slide-rtl 1s infinite linear; ; 
}
@keyframes ld-slide-btt
{
  0% {
    animation-timing-function: cubic-bezier(0.4652,0.1051,0.774,0.6426);
    transform: translate(0%,0%);
    opacity: 1;
  }
  22.5% {
    animation-timing-function: cubic-bezier(0.4142,0.3131,0.7623,0.6513);
    transform: translate(0%,-47.8%);
    opacity: 1;
  }
  45% {
    animation-timing-function: cubic-bezier(0.3615,0.331,0.9646,1.3461);
    transform: translate(0%,-147.8%);
    opacity: 1;
  }
  47.5% {
    animation-timing-function: cubic-bezier(0.7006,0.3332,0.6667,0.6667);
    transform: translate(0%,-165.6%);
    opacity: 0;
  }
  50% {
    animation-timing-function: cubic-bezier(0.1604,0.3176,-0.0218,0.6965);
    transform: translate(0%,200%);
    opacity: 0;
  }
  51% {
    animation-timing-function: cubic-bezier(0.1983,0.3529,0.5263,0.6945);
    transform: translate(0%,179.8%);
    opacity: 0;
  }
  80.4% {
    animation-timing-function: cubic-bezier(0.2342,0.3708,0.5422,0.9065);
    transform: translate(0%,38.4%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-btt {
  animation: ld-slide-btt 1s infinite linear; ; 
}
@keyframes ld-slide-ttb
{
  0% {
    animation-timing-function: cubic-bezier(0.4652,0.1051,0.774,0.6426);
    transform: translate(0%,0%);
    opacity: 1;
  }
  22.5% {
    animation-timing-function: cubic-bezier(0.4142,0.3131,0.7623,0.6513);
    transform: translate(0%,47.8%);
    opacity: 1;
  }
  45% {
    animation-timing-function: cubic-bezier(0.3615,0.331,0.9646,1.3461);
    transform: translate(0%,147.8%);
    opacity: 1;
  }
  47.5% {
    animation-timing-function: cubic-bezier(0.7006,0.3332,0.6667,0.6667);
    transform: translate(0%,165.6%);
    opacity: 0;
  }
  50% {
    animation-timing-function: cubic-bezier(0.1604,0.3176,-0.0218,0.6965);
    transform: translate(0%,-200%);
    opacity: 0;
  }
  51% {
    animation-timing-function: cubic-bezier(0.1983,0.3529,0.5263,0.6945);
    transform: translate(0%,-179.8%);
    opacity: 0;
  }
  80.4% {
    animation-timing-function: cubic-bezier(0.2342,0.3708,0.5422,0.9065);
    transform: translate(0%,-38.4%);
    opacity: 1;
  }
  100% {
    transform: translate(0%,0%);
    opacity: 1;
  }
}
.ld.ld-slide-ttb {
  animation: ld-slide-ttb 1s infinite linear; ; 
}
@keyframes ld-coin-h
{
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
.ld.ld-coin-h,.ld.ld-coin {
  animation: ld-coin-h 2s infinite linear; ; 
}
@keyframes ld-coin-v
{
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(3600deg);
  }
}
.ld.ld-coin-v {
  animation: ld-coin-v 2s infinite linear; ; 
}
@keyframes ld-cycle
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ld.ld-cycle {
  animation: ld-cycle 1s infinite linear; ; 
}
@keyframes ld-cycle-alt
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.ld.ld-cycle-alt {
  animation: ld-cycle-alt 1s infinite linear; ; 
}
@keyframes ld-flip-h
{
  0% {
    animation-timing-function: cubic-bezier(0.1909,0.4373,0.4509,0.7454);
    transform: rotateY(0deg);
  }
  30% {
    animation-timing-function: cubic-bezier(0.128,0.2315,0.9704,0.8632);
    transform: rotateY(153.72deg);
  }
  50% {
    animation-timing-function: cubic-bezier(0.5788,0.3001,0.5613,0.6784);
    transform: rotateY(180deg);
  }
  55% {
    animation-timing-function: cubic-bezier(0.1545,0.4929,0.6089,0.9373);
    transform: rotateY(238.68deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.ld.ld-flip-h {
  animation: ld-flip-h 1s infinite linear; ; 
}
@keyframes ld-flip-v
{
  0% {
    animation-timing-function: cubic-bezier(0.1909,0.4373,0.4509,0.7454);
    transform: rotateX(0deg);
  }
  30% {
    animation-timing-function: cubic-bezier(0.128,0.2315,0.9704,0.8632);
    transform: rotateX(153.72deg);
  }
  50% {
    animation-timing-function: cubic-bezier(0.5788,0.3001,0.5613,0.6784);
    transform: rotateX(180deg);
  }
  55% {
    animation-timing-function: cubic-bezier(0.1545,0.4929,0.6089,0.9373);
    transform: rotateX(238.68deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
.ld.ld-flip-v {
  animation: ld-flip-v 1s infinite linear; ; 
}
@keyframes ld-spin-fast
{
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.ld.ld-spin-fast {
  animation: ld-spin-fast 1s infinite linear; ; 
}
@keyframes ld-spin
{
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ld.ld-spin {
  animation: ld-spin 1s infinite linear; ; 
}
@keyframes ld-squeeze
{
  0% {
    animation-timing-function: cubic-bezier(0.1685,0.4459,0.3641,0.7833);
    transform: scale(0.5,1);
  }
  30% {
    animation-timing-function: cubic-bezier(0.0995,0.199,0.9948,0.959);
    transform: scale(0.9490000000000001,0.5509999999999999);
  }
  50% {
    animation-timing-function: cubic-bezier(0.6064,0.3078,0.5406,0.6764);
    transform: scale(1,0.5);
  }
  55% {
    animation-timing-function: cubic-bezier(0.1401,0.5826,0.6091,0.9651);
    transform: scale(0.8019999999999999,0.6980000000000001);
  }
  100% {
    transform: scale(0.5,1);
  }
}
.ld.ld-squeeze {
  animation: ld-squeeze 1s infinite linear; ; 
}
 @keyframes ld-static { 0% { } 100% { } } 
.ld.ld-static {
  animation: ld-static 1s infinite linear; ; 
}
@keyframes ld-surprise {
  0% { transform: skewX(0deg) scale(1); }
  10% { transform: skewX(-25deg) scale(0.5); }
  20% { transform: skewX(-25deg) scale(0.5); }
  30% { transform: skewX(25deg) scale(1); }
  40% { transform: skewX(-25deg) scale(1); }
  50% { transform: skewX(25deg) scale(1); }
  60% { transform: skewX(-25deg) scale(1); }
  70% { transform: skewX(25deg) scale(1); }
  80% { transform: skewX(-25deg) scale(1); }
  90% { transform: skewX(25deg) scale(1); }
  100% { transform: skewX(-25deg) scale(1); }
}
.ld.ld-surprise {
  animation: ld-surprise 1s infinite linear; ; 
}
@keyframes ld-measure {
  0% { transform: translate(0%,0%) rotate(0deg)  }
  3.33333% { transform: translate(0%,0%) rotate(-14.99241deg)  }
  6.66667% { transform: translate(0%,0%) rotate(10.93733deg)  }
  10% { transform: translate(0%,0%) rotate(10.60793deg)  }
  13.33333% { transform: translate(0%,0%) rotate(-10.26249deg)  }
  16.66667% { transform: translate(0%,0%) rotate(0.47337deg)  }
  20% { transform: translate(0%,0%) rotate(15deg)  }
  23.33333% { transform: translate(0%,0%) rotate(0.48087deg)  }
  26.66667% { transform: translate(0%,0%) rotate(-10.26796deg)  }
  30% { transform: translate(0%,0%) rotate(10.60262deg)  }
  33.33333% { transform: translate(0%,0%) rotate(10.94246deg)  }
  36.66667% { transform: translate(0%,0%) rotate(-14.99265deg)  }
  40% { transform: translate(0%,0%) rotate(-0.0075deg)  }
  43.33333% { transform: translate(0%,0%) rotate(14.99217deg)  }
  46.66667% { transform: translate(0%,0%) rotate(-10.93219deg)  }
  50% { transform: translate(0%,0%) rotate(-10.61323deg)  }
  53.33333% { transform: translate(0%,0%) rotate(10.25702deg)  }
  56.66667% { transform: translate(0%,0%) rotate(-0.46588deg)  }
  60% { transform: translate(0%,0%) rotate(-15deg)  }
  63.33333% { transform: translate(0%,0%) rotate(-0.48836deg)  }
  66.66667% { transform: translate(0%,0%) rotate(10.27343deg)  }
  70% { transform: translate(0%,0%) rotate(-10.59732deg)  }
  73.33333% { transform: translate(0%,0%) rotate(-10.94759deg)  }
  76.66667% { transform: translate(0%,0%) rotate(14.99288deg)  }
  80% { transform: translate(0%,0%) rotate(0.015deg)  }
  83.33333% { transform: translate(0%,0%) rotate(-14.99193deg)  }
  86.66667% { transform: translate(0%,0%) rotate(10.92706deg)  }
  90% { transform: translate(0%,0%) rotate(10.61853deg)  }
  93.33333% { transform: translate(0%,0%) rotate(-10.25155deg)  }
  96.66667% { transform: translate(0%,0%) rotate(0.45838deg)  }
  100% { transform: translate(0,0) rotate(0)  }
}
.ld.ld-measure {
  animation: ld-measure 5s infinite linear; ; 
}
@keyframes ld-shiver {
  0% { transform: translate(0%,0%)  scale(1) }
  3.33333% { transform: translate(0%,0%)  scale(1.04279) }
  6.66667% { transform: translate(0%,0%)  scale(1.00524) }
  10% { transform: translate(0%,0%)  scale(0.96369) }
  13.33333% { transform: translate(0%,0%)  scale(0.95064) }
  16.66667% { transform: translate(0%,0%)  scale(0.97651) }
  20% { transform: translate(0%,0%)  scale(1.02078) }
  23.33333% { transform: translate(0%,0%)  scale(1.04875) }
  26.66667% { transform: translate(0%,0%)  scale(1.03832) }
  30% { transform: translate(0%,0%)  scale(0.99777) }
  33.33333% { transform: translate(0%,0%)  scale(0.95897) }
  36.66667% { transform: translate(0%,0%)  scale(0.95244) }
  40% { transform: translate(0%,0%)  scale(0.98335) }
  43.33333% { transform: translate(0%,0%)  scale(1.02733) }
  46.66667% { transform: translate(0%,0%)  scale(1.04988) }
  50% { transform: translate(0%,0%)  scale(1.0331) }
  53.33333% { transform: translate(0%,0%)  scale(0.99035) }
  56.66667% { transform: translate(0%,0%)  scale(0.95507) }
  60% { transform: translate(0%,0%)  scale(0.95527) }
  63.33333% { transform: translate(0%,0%)  scale(0.99057) }
  66.66667% { transform: translate(0%,0%)  scale(1.03343) }
  70% { transform: translate(0%,0%)  scale(1.04984) }
  73.33333% { transform: translate(0%,0%)  scale(1.02714) }
  76.66667% { transform: translate(0%,0%)  scale(0.98293) }
  80% { transform: translate(0%,0%)  scale(0.9523) }
  83.33333% { transform: translate(0%,0%)  scale(0.9591) }
  86.66667% { transform: translate(0%,0%)  scale(0.99821) }
  90% { transform: translate(0%,0%)  scale(1.0386) }
  93.33333% { transform: translate(0%,0%)  scale(1.0487) }
  96.66667% { transform: translate(0%,0%)  scale(1.02038) }
  100% { transform: translate(0,0)  scale(1) }
}
.ld.ld-shiver {
  animation: ld-shiver 0.5s infinite linear; ; 
}
@keyframes ld-swim {
  0% { transform: translate(0px,0px) rotate(0deg)  }
  8.33333% { transform: translate(-5.96462px,4.90845px) rotate(-13.66821deg)  }
  16.66667% { transform: translate(5.25471px,-2.05606px) rotate(0.47337deg)  }
  25% { transform: translate(2.30929px,5.79372px) rotate(13.8564deg)  }
  33.33333% { transform: translate(-5.75556px,-4.60802px) rotate(10.94246deg)  }
  41.66667% { transform: translate(3.73522px,5.97742px) rotate(-14.03079deg)  }
  50% { transform: translate(4.2628px,-3.01222px) rotate(-10.61323deg)  }
  58.33333% { transform: translate(-4.65975px,-2.51269px) rotate(5.2869deg)  }
  66.66667% { transform: translate(1.64024px,-1.05167px) rotate(10.27343deg)  }
  75% { transform: translate(5.55954px,-4.22763px) rotate(-5.72726deg)  }
  83.33333% { transform: translate(-2.84602px,5.91439px) rotate(-14.99193deg)  }
  91.66667% { transform: translate(-0.70744px,-5.43064px) rotate(6.16192deg)  }
  100% { transform: translate(0,0) rotate(0)  }
}
.ld.ld-swim {
  animation: ld-swim 10s infinite linear; ; 
}
@keyframes ld-tremble {
  0% { transform: translate(0px,0px)   }
  3.33333% { transform: translate(-0.18923px,1.45485px)   }
  6.66667% { transform: translate(-0.84296px,-1.32524px)   }
  10% { transform: translate(0.67971px,1.00422px)   }
  13.33333% { transform: translate(-0.5056px,0.83616px)   }
  16.66667% { transform: translate(1.31368px,-0.51401px)   }
  20% { transform: translate(-1.21184px,1.49193px)   }
  23.33333% { transform: translate(1.09065px,-0.21259px)   }
  26.66667% { transform: translate(-1.49916px,0.56159px)   }
  30% { transform: translate(1.48086px,1.21228px)   }
  33.33333% { transform: translate(-1.43889px,-1.152px)   }
  36.66667% { transform: translate(1.35914px,1.34835px)   }
  40% { transform: translate(-1.42834px,0.3091px)   }
  43.33333% { transform: translate(1.47472px,-1.49889px)   }
  46.66667% { transform: translate(-0.92402px,1.4416px)   }
  50% { transform: translate(1.0657px,-0.75306px)   }
  53.33333% { transform: translate(-1.19035px,-1.07484px)   }
  56.66667% { transform: translate(0.28828px,0.79337px)   }
  60% { transform: translate(-0.47167px,-1.42789px)   }
  63.33333% { transform: translate(0.64753px,-0.09795px)   }
  66.66667% { transform: translate(0.41006px,-0.26292px)   }
  70% { transform: translate(-0.22477px,-1.3683px)   }
  73.33333% { transform: translate(0.03588px,0.92931px)   }
  76.66667% { transform: translate(-1.01937px,-1.18398px)   }
  80% { transform: translate(0.8724px,-0.60494px)   }
  83.33333% { transform: translate(-0.71151px,1.4786px)   }
  86.66667% { transform: translate(1.40734px,-1.49607px)   }
  90% { transform: translate(-1.33062px,0.46957px)   }
  93.33333% { transform: translate(1.23264px,1.26738px)   }
  96.66667% { transform: translate(-1.48975px,-1.03867px)   }
  100% { transform: translate(0,0)   }
}
.ld.ld-tremble {
  animation: ld-tremble 0.5s infinite linear; ; 
}
@keyframes ld-vortex {
  0%, 60% { animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7); }
  0% { opacity: 0; transform: rotate(-1800deg) scale(0.3); }
  60% { opacity: 1; transform: rotate(0deg) scale(1); }
  100% { opacity: 0; transform: rotate(0deg) scale(1); }
}
.ld.ld-vortex {
  animation: ld-vortex 1s infinite linear; ; 
}
@keyframes ld-vortex-alt {
  0%, 60% { animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7); }
  0% { opacity: 0; transform: rotate(-1800deg) scale(3); }
  60% { opacity: 1; transform: rotate(0deg) scale(1); }
  100% { opacity: 0; transform: rotate(0deg) scale(1); }
}
.ld.ld-vortex-alt {
  animation: ld-vortex-alt 1s infinite linear; ; 
}
@keyframes ld-wrench {
  20%, 36%, 70%, 86% {
    transform: rotate(0deg);
  }
  0%, 50%, 100% {
    transform: rotate(45deg);
  }
}
.ld.ld-wrench {
  animation: ld-wrench 1s infinite linear; ; 
}